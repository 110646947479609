// node_modules
import axios from "axios";
// Types
import { TAxiosParams, TSynonymDTO } from "Types";
// Helpers
import { LogFeatureNameEnum, SynonymTypeEnum } from "Enums";
import { AxiosHelperSingleton, LogHelperSingleton, SynonymsHelperSingleton } from "Helpers";

export class SynonymController {

    public async createAsync(queryId: string, fieldId: number, synonymToCreate: TSynonymDTO): Promise<TSynonymDTO | undefined> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/fields/${fieldId}/synonyms`;

            // make request
            const response = await axios.post<TSynonymDTO>(url, synonymToCreate);

            // deal with response
            if (response?.data) {
              const synonymTypeText = SynonymsHelperSingleton.getSynonymTypeText(synonymToCreate.synonymType);
              if (synonymTypeText) {
                LogHelperSingleton.log(`${LogFeatureNameEnum.AdvancedSearch}-AddSynonymTo${synonymTypeText}`);
              }
              return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getGeneratedAsync(queryId: string, fieldId: number, value: string, synonymsType: SynonymTypeEnum): Promise<string[]> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/fields/${fieldId}/synonyms/generated`;

            // make request
            const response = await axios.get<string[]>(url, {
                params: {
                    value: value,
                    synonymsType: synonymsType
                }
            });
            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getHistoricalAsync(queryId: string, fieldId: number, value: string, synonymsType: SynonymTypeEnum, synonymId?: number): Promise<string[]> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/fields/${fieldId}/synonyms/historical`;
            const axiosParams: TAxiosParams = {
                value: value,
                synonymsType: synonymsType
            };

            // if synonymId is defined, add it to the url
            if (synonymId) {
                axiosParams.synonymId = synonymId;
            }

            // make request
            const response = await axios.get<string[]>(url, {
                params: axiosParams
            });

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async updateAsync(queryId: string, fieldId: number, synonym: TSynonymDTO): Promise<TSynonymDTO | undefined> {
        try {
        // build url
        const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/fields/${fieldId}/synonyms/${synonym.id}`;

        // make request
        const response = await axios.put<TSynonymDTO>(url, synonym);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async deleteAsync(queryId: string, fieldId: number, synonymId: number): Promise<boolean> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/fields/${fieldId}/synonyms/${synonymId}`;

            // make request
            const response = await axios.delete<void>(url);

            // deal with response
            if (response && response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const SynonymControllerSingleton = new SynonymController(); 