// node_modules
import { FC, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { Dropdown, FindestTextBox } from "Components";
import { CreateObjectModal } from "./CreateObjectModal";
// Controllers
import { SearchControllerSingleton, StudyControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum, StudyTypeEnum, ToastTypeEnum } from "Enums";
// Interfaces
import { IStudyDTO } from "Interfaces";
// Styles
import sharedModalStyles from "Components/Shared/Modals/modal.module.scss";
import styles from "./createObjectModal.module.scss";
// Helpers
import { LogHelperSingleton, StudyTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import { TOption, TOptions } from "Types";
// Constants
import { StudyConstants } from "Constants";

// Component props type
type TCreateStudyModalProps = {
    onCreationDone: () => void
};

// Component
export const CreateStudyModal: FC<TCreateStudyModalProps> = ({ onCreationDone }: TCreateStudyModalProps) => {
    // State
    const [selectedOption, setSelectedOption] = useState<TOption<StudyTypeEnum>>(StudyTypeHelperSingleton.defaultStudyTypeOption);
    const [customTypeName, setCustomTypeName] = useState<string>("");
    const [allStudyTypesDropdownOptions, setAllStudyTypesDropdownOptions] = useState<TOptions<StudyTypeEnum>[]>([]);

    // Hooks
    const navigate = useNavigate();

    const getIsCreateButtonDisabled = useCallback(() => {
        // if entity type is custom and custom type name is not set, disable create button
        if (selectedOption.value === StudyTypeEnum.Custom && selectedOption.title === StudyConstants.CREATE_CUSTOM_TYPE_OPTION) {
            return customTypeName.length < 1;
        }

        // otherwise, do not disable create button
        return false;
    }, [customTypeName.length, selectedOption.title, selectedOption.value]);

    // Logic
    const onCreateClickAsync = useCallback(async (title: string): Promise<void> => {
        // call server to create study in db
        const createdStudy = await StudyControllerSingleton.createAsync(title, "", selectedOption.value, customTypeName);

        // safety-checks
        if(!createdStudy) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to create study.");
            return;
        }

        // reset state and close modal
        resetModalStateAndClose();

        // log
        LogHelperSingleton.log("CreateStudy");

        // navigate to related study details page
        navigate(`/library/studies/${createdStudy.id}`);

        // call onCreationDone callback
        onCreationDone();
    }, [customTypeName, navigate, onCreationDone, selectedOption.value]);

    const refreshCustomStudyTypesAsync = useCallback(async () => {
        const allStudyTypeDropdownOptionsGroups = await StudyTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(true, false);
        setAllStudyTypesDropdownOptions(allStudyTypeDropdownOptionsGroups);
    }, []);

    useEffect(() => {
        refreshCustomStudyTypesAsync();
    }, [refreshCustomStudyTypesAsync]);

    const resetModalStateAndClose = () => {
        setCustomTypeName("");
        setSelectedOption(StudyTypeHelperSingleton.defaultStudyTypeOption);
    };

    const handleNewStudyTypeSelected = (option: TOption<StudyTypeEnum>) => {
        // if a custom type is selected
        if (option.value === StudyTypeEnum.Custom) {
            // if custom type is the one to create a new custom type
            if (option.title === StudyConstants.CREATE_CUSTOM_TYPE_OPTION) {
                // set custom type name to empty string
                setCustomTypeName("");
            } else {
                // otherwise set custom type name
                setCustomTypeName(option.title);
            }
        }
        // set selected option
        setSelectedOption(option);
    };

    return (
        <CreateObjectModal 
            buttonText="Create study"
            suggestionPlaceholder="Enter study title"
            suggestionTitle="Similar studies in the universe"
            isCreateButtonDisabled={getIsCreateButtonDisabled()}
            searchForSuggestionsAsync={async (suggestionValue: string) => { return await SearchControllerSingleton.searchStudiesAsync(suggestionValue); }}
            fromObjectToSuggestionBuilder={(study: IStudyDTO) => {
                return {
                    id: study.id,
                    name: study.title,
                    objectType: ObjectTypeEnum.Study,
                    type: StudyTypeHelperSingleton.getStudyTypeDisplayName(study.type, study.customTypeName)
                };
            }}
            navigateToSuggestionPath="/library/studies/"
            onCreateClickAsync={onCreateClickAsync}
            closeModal={onCreationDone}
        >
            <div className={sharedModalStyles.section}>
                <div className={styles.title}>Study type</div>
                <Dropdown
                    selectedOption={selectedOption}
                    handleOptionSelect={handleNewStudyTypeSelected}
                    options={allStudyTypesDropdownOptions}
                    placeholderText="Select study type"
                    extraClassNames={{
                        positionedPopup: styles.objectTypeDropdownPopup,
                        groupedList: {
                            groupedListContainer: styles.groupedListContainer,
                            groupedList: styles.groupedList,
                            option: styles.dropdownOption,
                            groupTitle: styles.dropdownGroupTitle
                        },
                        dropdownSelectText: styles.dropdownSelectText
                    }}
                />
                {selectedOption.value === StudyTypeEnum.Custom && selectedOption.title === StudyConstants.CREATE_CUSTOM_TYPE_OPTION && (
                    <FindestTextBox 
                        value={customTypeName} 
                        onChange={(text: string) => setCustomTypeName(text)} 
                        placeholder={"Type custom type name..."}
                        extraClassName={styles.customTypeNameTextBox} />
                )}
            </div>
        </CreateObjectModal>
    );
};