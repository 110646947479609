export const ErrorConstants = {
  DEFAULT_ERROR_MESSAGE: "Something went wrong! Please contact our support team.",
  NO_ACCESS_TO_UNIVERSE: `You do not have access to the Universe.
 If you think this is a mistake or if you are interested in the Universe,
 please contact us at support@findest.eu`,
  SUPPORT_EMAIL: "support@findest.eu",
  // Query errors
  A_FUNCTION_CAN_ONLY_HAVE_ONE_ACTION: "A function can only have one action.",
  A_FUNCTION_NEEDS_ONE_ACTION_AND_AT_LEAST_ONE_OBJECT: "A function needs one action and at least one object.",
  ACTION_OBJECT_ALREADY_EXISTS: "Action object already exists.",
  A_KEYWORD_CAN_NOT_BE_EMPTY: "A keyword can not be empty.",
  KEYWORD_ALREADY_EXISTS: "Keyword already exists.",
  A_SYNONYM_CAN_NOT_BE_EMPTY: "A synonym can not be empty.",
  AN_ACTION_SYNONYM_CAN_NOT_HAVE_MORE_THAN_ONE_WORD: "An action synonym can not have more than one word.",
  SYNONYM_ALREADY_EXISTS: "Synonym already exists.",
} as const;
