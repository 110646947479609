import { ActionObjectControllerSingleton } from "Controllers";
import { SearchPriorityEnum, ToastTypeEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { TActionObjectDTO } from "Types";
import { IQueryDTO } from "Interfaces";
import { Dispatch, SetStateAction } from "react";

export class ActionObjectHelper {
  public async addActionObjectAsync(action: string, object: string, currentQuery: IQueryDTO, setQuery?: Dispatch<SetStateAction<IQueryDTO | undefined>>): Promise<TActionObjectDTO | undefined> {
    // create action object dto
    const actionObjectToCreateDTO: TActionObjectDTO = {
      id: 0,
      action: action,
      actionSynonymsAmount: 0,
      actionSynonyms: [],
      dobject: object,
      objectSynonymsAmount: 0,
      objectSynonyms: [],
      searchPriority: SearchPriorityEnum.Should,
      actionTermGroupName: "",
      objectTermGroupName: ""
    };

    // create action object
    const createdActionObject: TActionObjectDTO | undefined = await ActionObjectControllerSingleton
      .createAsync(currentQuery.guid, actionObjectToCreateDTO);

    // safety-checks
    if (!createdActionObject) {
      ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not create action object.");
      return;
    }

    setQuery?.((prevQuery: IQueryDTO | undefined) => {
      // safety-checks
      if (!prevQuery) {
        return prevQuery;
      }

      // return query
      // add action object to query
      return {
        ...prevQuery,
        actionObjects: [...prevQuery.actionObjects, createdActionObject]
      };
    });

    return createdActionObject;
  }
}

export const ActionObjectHelperSingleton = new ActionObjectHelper();
