
import { SynonymTypeEnum, ToastTypeEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { TSynonymDTO } from "Types";
import { SynonymControllerSingleton } from "Controllers";
import { SetStateAction } from "react";
import { IQueryDTO } from "Interfaces";

export class SynonymsHelper {
  public async addSynyonymAsync(currentQueryId: string, synonymValue: string, currentSynonymsType: SynonymTypeEnum, currentFieldId: number,
    currentSynonyms: TSynonymDTO[],
    updateFieldSynonymsParam: (synonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number) => void): Promise<TSynonymDTO | undefined> {
    // create synonym dto
    const synonymToCreateDTO: TSynonymDTO = {
        id: 0,
        text: synonymValue,
        synonymType: currentSynonymsType,
        required: true
    };

    // create synonym
    const createdSynonym: TSynonymDTO | undefined = await SynonymControllerSingleton
        .createAsync(currentQueryId, currentFieldId, synonymToCreateDTO);

    // safety-checks
    if (!createdSynonym) {
        ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not create synonym.");
        return;
    }

    // build new synonyms list
    const newSynonymsList = [...currentSynonyms, createdSynonym];

    // update synonyms list
    updateFieldSynonymsParam(newSynonymsList, currentSynonymsType, currentFieldId);

    return createdSynonym;
  }

  public isSynonymValueEmpty(synonymValue: string): boolean {
    return !synonymValue || synonymValue.trim() === "";
  }

  public updateFieldSynonyms(synoymsParam: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number, setQuery: (value: SetStateAction<IQueryDTO | undefined>) => void): void{
    setQuery((prevQuery: IQueryDTO | undefined) => {
        // safety-checks
        if (!prevQuery) {
            return prevQuery;
        }

        if (currentSynonymsType === SynonymTypeEnum.Action || currentSynonymsType === SynonymTypeEnum.Object) {
            // iterate through action objects
            for (const actionObject of prevQuery.actionObjects) {
                // if field id is the same as the current action object id
                if (actionObject.id === currentFieldId) {
                    // update synonyms
                    if (currentSynonymsType === SynonymTypeEnum.Action) {
                        actionObject.actionSynonyms = [...synoymsParam];
                    } else if (currentSynonymsType === SynonymTypeEnum.Object) {
                        actionObject.objectSynonyms = [...synoymsParam];
                    }
                    break;
                }
            }
        } else if (currentSynonymsType === SynonymTypeEnum.Environment) {
            // iterate through environment variables
            for (const environmentVariable of prevQuery.environmentVariables) {
                // if field id is the same as the current environment variable id
                if (environmentVariable.id === currentFieldId) {
                    // update synonyms
                    environmentVariable.synonyms = [...synoymsParam];
                    break;
                }
            }
        }

        return {
            ...prevQuery,
            actionObjects: [...prevQuery.actionObjects],
            environmentVariables: [...prevQuery.environmentVariables]
        };
    });
  }

  public getSynonymTypeText(synonymType: SynonymTypeEnum): "Action" | "Object" | "Keyword" | "" {
    switch (synonymType) {
        case SynonymTypeEnum.Action:
            return "Action";
        case SynonymTypeEnum.Object:
            return "Object";
        case SynonymTypeEnum.Environment:
            return "Keyword";
        default:
            return "";
    }
  }
}

export const SynonymsHelperSingleton = new SynonymsHelper();
