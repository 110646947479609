// node_modules
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { faLink, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
// Components
import { EmptyObjects, LinkingModal, ListHeader, LoadingStatusIndicator } from "Components";
import { StudyItem } from "./StudyItem";
// Controllers
import { StudyControllerSingleton } from "Controllers";
// Types
import { TButtonDefinition, TOption, TOptions, TStudiesDTO } from "Types";
// Enums
import {
  OrderByEnum,
  ObjectTypeEnum,
  OwnershipEnum,
  SortTypeEnum,
  StudyStatusEnum,
  StudyTypeEnum,
} from "Enums";
// Styles
import entityLikeListStyles from "Styles/EntityLikeList/entityLikeList.module.scss";
import listHeaderStyles from "Components/Shared/Lists/ListHeader/listHeader.module.scss";
// Constants
import { LinkingConstants, StudyConstants } from "Constants";
// Helpers
import {
  FilterHelperSingleton,
  LogHelperSingleton,
  OwnershipHelperSingleton,
  SavedFiltersHelperSingleton,
  StudyStatusHelperSingleton,
  StudyTypeHelperSingleton,
} from "Helpers";
// Interfaces
import { IStudyDTO } from "Interfaces";
// Custom hooks
import { useCheckboxedList, useStudyNameChangeListener } from "Hooks";
import { ElementVisibilityContext, PinnedContext } from "Providers";

export const Studies: FC = () => {
  // Contexts
  const { canUserEdit } = useContext(ElementVisibilityContext);
  const { refreshPins } = useContext(PinnedContext);

  // State
  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [
    studyTypesDropdownOptionsWithoutCreate,
    setStudyTypesDropdownOptionsWithoutCreate,
  ] = useState<TOptions<StudyTypeEnum>[]>([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    TOption<StudyTypeEnum | StudyStatusEnum | OwnershipEnum>[]
  >([]);
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
  const [studies, setStudies] = useState<IStudyDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalStudiesCount, setTotalStudiesCount] = useState<number>(0);
  const [lastPaginationFromDates, setLastPaginationFromDates] = useState<
    Date[]
  >([]);

  // Refs
  const container = useRef<HTMLDivElement | null>(null);

  // Memos
  const availableFilterOptions = useMemo(() => {
    let result: TOptions<StudyTypeEnum | StudyStatusEnum | OwnershipEnum>[] = [
      ...studyTypesDropdownOptionsWithoutCreate,
      ...StudyStatusHelperSingleton.studyStatusFilterDropdownOptions,
    ];

    if (canUserEdit) {
      result = result.concat(
        ...OwnershipHelperSingleton.ownershipFilterDropdownOptions
      );
    }

    return result;
  }, [studyTypesDropdownOptionsWithoutCreate, canUserEdit]);

  // Hooks
  const {
    selectedItems: selectedStudies,
    setSelectedItems: setSelectedStudies,
    areAllItemsSelected: areAllStudiesSelected,
    isAnyItemSelected: isAnyStudySelected,
    onSelectAllItems,
    onSelectItem,
  } = useCheckboxedList<IStudyDTO>(studies, "Study", "Studies", (study) => ({
    id: study.id,
    type: "Study",
    name: study.title,
    objectType: ObjectTypeEnum.Study,
  }));

  // Logic
  useEffect(() => {
    // get saved filters in local storage
    const savedFilters: TOption<
      StudyTypeEnum | StudyStatusEnum | OwnershipEnum
    >[] = SavedFiltersHelperSingleton.getStudiesFilters();

    // if there are saved filters, set them as selected
    if (savedFilters.length > 0) {
      setSelectedFilterOptions(savedFilters);
    }

    refreshStudiesAsync(
      undefined,
      getSelectedStatusesFilterOptions(savedFilters),
      getSelectedTypesFilterOptions(savedFilters),
      SortTypeEnum.Newest,
      FilterHelperSingleton.getIsCreatedByMeSelected(savedFilters),
      getSelectedCustomTypeNames(savedFilters)
    );

    // log
    LogHelperSingleton.log("DisplayStudies");
  }, []);

  useEffect(() => {
    (async () => {
      const customTypeDropdownOptionsWithoutCreate =
        await StudyTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(
          false,
          false
        );
      setStudyTypesDropdownOptionsWithoutCreate(
        customTypeDropdownOptionsWithoutCreate
      );
    })();
  }, []);

  const selectedFilterDropdownOptions = useMemo(() => {
    const newSelectedFilterDropdownOptions: TOption<
      StudyTypeEnum | StudyStatusEnum | OwnershipEnum
    >[] = [];

    for (const selectedFilterOption of selectedFilterOptions) {
      if (
        StudyStatusHelperSingleton.allStudyStatuses.includes(
          selectedFilterOption.value as StudyStatusEnum
        )
      ) {
        newSelectedFilterDropdownOptions.push({
          value: selectedFilterOption.value,
          title: StudyStatusHelperSingleton.getStudyStatusDisplayName(
            selectedFilterOption.value as StudyStatusEnum
          ),
        });
      } else if (
        (selectedFilterOption.value as OwnershipEnum) === OwnershipEnum.Me
      ) {
        newSelectedFilterDropdownOptions.push({
          value: selectedFilterOption.value,
          title: OwnershipHelperSingleton.getOwnershipDisplayName(
            selectedFilterOption.value as OwnershipEnum
          ),
          customTitle: OwnershipHelperSingleton.getOwnershipCustomTitle(
            selectedFilterOption.value as OwnershipEnum
          ),
        });
      } else if (
        StudyTypeHelperSingleton.allStudyTypes.includes(
          selectedFilterOption.value as StudyTypeEnum
        )
      ) {
        newSelectedFilterDropdownOptions.push({
          value: selectedFilterOption.value,
          title: StudyTypeHelperSingleton.getStudyTypeDisplayName(
            selectedFilterOption.value as StudyTypeEnum,
            selectedFilterOption.title
          ),
        });
      }
    }

    return newSelectedFilterDropdownOptions;
  }, [selectedFilterOptions]);

  const getSelectedStatusesFilterOptions = (
    filterOptions: TOption<StudyTypeEnum | StudyStatusEnum | OwnershipEnum>[]
  ) => {
    const newSelectedStatusesFilterOptionsForDropdown: StudyStatusEnum[] = [];

    for (const selectedFilterOption of filterOptions) {
      if (
        StudyStatusHelperSingleton.allStudyStatuses.includes(
          selectedFilterOption.value as StudyStatusEnum
        )
      ) {
        newSelectedStatusesFilterOptionsForDropdown.push(
          selectedFilterOption.value as StudyStatusEnum
        );
      }
    }

    return newSelectedStatusesFilterOptionsForDropdown;
  };

  const getSelectedTypesFilterOptions = (
    filterOptions: TOption<StudyTypeEnum | StudyStatusEnum | OwnershipEnum>[]
  ) => {
    const newSelectedTypesFilterOptionsForDropdown: StudyTypeEnum[] = [];

    for (const selectedFilterOption of filterOptions) {
      if (
        StudyTypeHelperSingleton.allStudyTypes.includes(
          selectedFilterOption.value as StudyTypeEnum
        )
      ) {
        newSelectedTypesFilterOptionsForDropdown.push(
          selectedFilterOption.value as StudyTypeEnum
        );
      }
    }

    return newSelectedTypesFilterOptionsForDropdown;
  };

  const getSelectedCustomTypeNames = (
    filterOptions: TOption<StudyTypeEnum | StudyStatusEnum | OwnershipEnum>[]
  ) => {
    const currentSelectedCustomTypeNames: string[] = [];

    for (const filterOption of filterOptions) {
      if (filterOption.value === StudyTypeEnum.Custom) {
        currentSelectedCustomTypeNames.push(filterOption.title);
      }
    }

    return currentSelectedCustomTypeNames;
  };

  const onSelectAllCheckboxChange = (isChecked: boolean) => {
    onSelectAllItems(isChecked, studies);
  };

  const onStudyCheckboxChange = (isChecked: boolean, id: string): void => {
    const currentStudy = studies.find((study) => study.id === id);
    if (!currentStudy) {
      return;
    }
    onSelectItem(isChecked, currentStudy, id);
  };

  const updateSortTypeAsync = async (
    newSortType: SortTypeEnum
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    // safety-checks
    if (newSortType === sortType) {
      return;
    }

    // set new sort type
    setSortType(newSortType);

    // update studies list
    await refreshStudiesAsync(
      undefined,
      getSelectedStatusesFilterOptions(selectedFilterOptions),
      getSelectedTypesFilterOptions(selectedFilterOptions),
      newSortType,
      FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
      getSelectedCustomTypeNames(selectedFilterOptions)
    );

    // reset selected studies
    setSelectedStudies([]);

    // log
    LogHelperSingleton.log("SortStudies");
  };

  const updateFilterOptionsAsync = async (
    action: "add" | "remove",
    option: TOption<StudyTypeEnum | StudyStatusEnum | OwnershipEnum>
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    setIsLoading(true);

    let newFilterOptions = [];

    if (action === "add") {
      newFilterOptions = [...selectedFilterOptions].concat([option]);
    } else {
      for (const selectedFilterOption of selectedFilterOptions) {
        if (option.value === StudyTypeEnum.Custom) {
          if (
            selectedFilterOption.value === option.value &&
            selectedFilterOption.title === option.title
          ) {
            continue;
          }
        } else {
          if (selectedFilterOption.value === option.value) {
            continue;
          }
        }
        newFilterOptions.push(selectedFilterOption);
      }
    }

    setSelectedFilterOptions(newFilterOptions);

    // save filters in local storage
    SavedFiltersHelperSingleton.saveStudiesFilters(newFilterOptions);

    // update studies list
    await refreshStudiesAsync(
      undefined,
      getSelectedStatusesFilterOptions(newFilterOptions),
      getSelectedTypesFilterOptions(newFilterOptions),
      sortType,
      FilterHelperSingleton.getIsCreatedByMeSelected(newFilterOptions),
      getSelectedCustomTypeNames(newFilterOptions)
    );

    // reset selected studies
    setSelectedStudies([]);

    setIsLoading(false);

    // log
    LogHelperSingleton.log("FilterStudies");
  };

  const onPaginatePreviousAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (lastPaginationFromDates && lastPaginationFromDates.length > 0) {
      lastPaginationFromDates.pop();
      if (lastPaginationFromDates.length >= 1) {
        fromDate = lastPaginationFromDates[lastPaginationFromDates.length - 1];
      }
      setLastPaginationFromDates(lastPaginationFromDates);
    }

    // update studies list
    await refreshStudiesAsync(
      fromDate,
      getSelectedStatusesFilterOptions(selectedFilterOptions),
      getSelectedTypesFilterOptions(selectedFilterOptions),
      sortType,
      FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
      getSelectedCustomTypeNames(selectedFilterOptions)
    );

    // log
    LogHelperSingleton.log("GoToPreviousStudiesPage");
  };

  const onPaginateNextAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (studies && studies.length > 0) {
      const lastPaginationFromDate: Date =
        studies[studies.length - 1].dateAdded;
      fromDate = lastPaginationFromDate;
      lastPaginationFromDates.push(fromDate);
      setLastPaginationFromDates(lastPaginationFromDates);
    }

    // update studies list
    await refreshStudiesAsync(
      fromDate,
      getSelectedStatusesFilterOptions(selectedFilterOptions),
      getSelectedTypesFilterOptions(selectedFilterOptions),
      sortType,
      FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
      getSelectedCustomTypeNames(selectedFilterOptions)
    );

    // log
    LogHelperSingleton.log("GoToNextStudiesPage");
  };

  const onDeleteStudiesAsync = useCallback(
    async (studiesToDelete: IStudyDTO[]): Promise<void> => {
      // safety-checks
      if (!studiesToDelete || studiesToDelete.length < 1) {
        return;
      }

      // Confirm with the user that they want to delete the documents
      if (studiesToDelete.length === 1) {
        if (!confirm(StudyConstants.DELETE_STUDY_CONFIRMATION)) return;
      } else {
        if (!confirm(StudyConstants.DELETE_STUDIES_CONFIRMATION)) return;
      }

      // bulk delete studies
      await StudyControllerSingleton.bulkDeleteAsync(
        studiesToDelete.map((study) => study.id)
      );

      // update studies list
      await refreshStudiesAsync(
        undefined,
        getSelectedStatusesFilterOptions(selectedFilterOptions),
        getSelectedTypesFilterOptions(selectedFilterOptions),
        sortType,
        FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
        getSelectedCustomTypeNames(selectedFilterOptions)
      );

      // reset selected studies
      setSelectedStudies([]);

      // set total studies count
      setTotalStudiesCount(totalStudiesCount - studiesToDelete.length);

      // refresh pins
      await refreshPins();

      // log
      LogHelperSingleton.log("RemoveStudy(ies)");
    },
    [
      refreshPins,
      selectedFilterOptions,
      setSelectedStudies,
      sortType,
      totalStudiesCount,
    ]
  );

  const onDeleteClickAsync = useCallback(async (): Promise<void> => {
    // safety-checks
    if (selectedStudies.length === 0) {
      return;
    }

    // deleted selected studies
    await onDeleteStudiesAsync(
      studies.filter(
        (study) =>
          selectedStudies.find(
            (selectedStudy) => selectedStudy.id === study.id
          ) !== undefined
      )
    );
  }, [onDeleteStudiesAsync, selectedStudies, studies]);

  const refreshStudiesAsync = async (
    fromDate: Date | undefined,
    studyStatuses: StudyStatusEnum[],
    studyTypes: StudyTypeEnum[],
    currentSortType: SortTypeEnum,
    createdByMe: boolean,
    customTypeNames: string[]
  ): Promise<void> => {
    const newStudies: TStudiesDTO = await StudyControllerSingleton.getAsync(
      currentSortType === SortTypeEnum.Oldest
        ? OrderByEnum.Ascending
        : OrderByEnum.Descending,
      fromDate,
      studyStatuses,
      studyTypes,
      createdByMe,
      customTypeNames
    );

    // set state variables
    setStudies(newStudies.studies);
    setTotalStudiesCount(newStudies.totalStudiesCount);
    setIsLoading(false);

    // Scroll to top of the component
    container.current?.scrollTo({ top: 0 });
  };

  const onLinkStudy = (study: IStudyDTO) => {
    setSelectedStudies([
      {
        id: study.id,
        name: study.title,
        type: "Study",
        objectType: ObjectTypeEnum.Study,
      },
    ]);
    setIsLinkModalOpen(true);
    // log
    LogHelperSingleton.log("StartLinkingStudy");
  };

  const onLinkStudiesClick = () => {
    setIsLinkModalOpen(true);
    // log
    LogHelperSingleton.log("StartLinkingStudy(ies)");
  };

  const onLinkingDoneAsync = useCallback(
    async (isLinkingDone: boolean): Promise<void> => {
      // if linking is done
      if (isLinkingDone) {
        // refresh studies list
        await refreshStudiesAsync(
          lastPaginationFromDates[lastPaginationFromDates.length - 1],
          getSelectedStatusesFilterOptions(selectedFilterOptions),
          getSelectedTypesFilterOptions(selectedFilterOptions),
          sortType,
          FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
          getSelectedCustomTypeNames(selectedFilterOptions)
        );
        // set selected studies to empty
        setSelectedStudies([]);
      }
    },
    [
      lastPaginationFromDates,
      selectedFilterOptions,
      setSelectedStudies,
      sortType,
    ]
  );

  const studiesHeaderButtons = useMemo(() => {
    return [
      {
        title: "Link",
        icon: faLink,
        onClick: onLinkStudiesClick,
        className: listHeaderStyles.linkIcon,
      },
      {
        title: "Delete",
        icon: faTrashCan,
        onClick: onDeleteClickAsync,
        className: listHeaderStyles.trashIcon,
      },
    ] as TButtonDefinition[];
  }, [onDeleteClickAsync]);

  // Hooks live update the Studies name
  useStudyNameChangeListener(setStudies);

  if (selectedFilterDropdownOptions.length === 0) {
    if (isLoading) return <LoadingStatusIndicator shouldCenter status={1} />;

    if (studies.length === 0) {
      return <EmptyObjects route="Studies" objectType={ObjectTypeEnum.Study} description={StudyConstants.CREATE_STUDY_DESCRIPTION} />;
    }
  }

  return (
    <div className={entityLikeListStyles.entityLikeContainer} ref={container}>
      <div className={entityLikeListStyles.entityLikeItems}>
        <ListHeader
          isAllListItemsSelected={areAllStudiesSelected}
          isAnyListItemSelected={isAnyStudySelected}
          onSelectAllCheckboxChange={
            canUserEdit ? onSelectAllCheckboxChange : undefined
          }
          selectedFilterOptions={selectedFilterDropdownOptions}
          updateFilterOptions={updateFilterOptionsAsync}
          sortType={sortType}
          updateSortType={updateSortTypeAsync}
          totalListItemCount={totalStudiesCount}
          onPaginatePrevious={onPaginatePreviousAsync}
          onPaginateNext={onPaginateNextAsync}
          filterOptions={availableFilterOptions}
          listItemCountInterval={StudyConstants.MAXIMUM_STUDIES_TO_RETRIEVE}
          buttonDefinitions={studiesHeaderButtons}
        />
        <div className={entityLikeListStyles.entityLikeItemsList}>
          {studies.map((study: IStudyDTO) => {
            const isSelected =
              selectedStudies.find(
                (selectedStudy) => selectedStudy.id === study.id
              ) !== undefined;
            return (
              <StudyItem
                key={`studyitem-${study.id}`}
                study={study}
                isSelected={isSelected}
                onCheckboxChange={
                  canUserEdit ? onStudyCheckboxChange : undefined
                }
                onDeleteStudyClick={
                  canUserEdit
                    ? (studyToDelete: IStudyDTO) =>
                        onDeleteStudiesAsync([studyToDelete])
                    : undefined
                }
                onLinkStudyClick={canUserEdit ? onLinkStudy : undefined}
              />
            );
          })}
        </div>
      </div>
      <LinkingModal
        isOpen={isLinkModalOpen}
        setIsOpen={setIsLinkModalOpen}
        selectedObjects={selectedStudies}
        onLinkingDoneAsync={onLinkingDoneAsync}
        defaultLinkType={LinkingConstants.CHILD_LINK_TYPE}
      />
    </div>
  );
};
