// node_modules
import { FC, useContext, useEffect, useMemo, useState } from "react";
// Components
import { AskIgorMenuItem } from "./AskIgorMenuItem/AskIgorMenuItem";
import { Checkbox, FindestButton, Popover, Tabs, Tooltip } from "Components";
// Types
import {
  TAskIgorRequirement,
  TAskIgorSettings,
  TIdNameTypeObjectType,
  TTab,
} from "Types";
// Enums
import { ObjectTypeEnum, AskIgorMenuItemEnum } from "Enums";
// Constants
import { FeatureToggleConstants } from "Constants";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faAward,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faFile,
  faFlask,
  faGlobe,
  faHighlighter,
  faMessageBot,
} from "@fortawesome/pro-solid-svg-icons";
// Controllers
import {
  HighlightControllerSingleton,
  SavedDocumentControllerSingleton,
} from "Controllers";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Providers
import { EditorContext } from "Providers";
// Styles
import styles from "./askIgorMenu.module.scss";

type TAskIgorMenuProps = {
  object: TIdNameTypeObjectType;
  selectedItem: AskIgorMenuItemEnum | undefined;
  selectedDocumentsOfIgor: string[];
  defaultInput?: string;
  selectedSavedDocumentsFormList: ISavedDocumentDTO[] | undefined;
  onDocumentTypesChange: (documentTypes: ObjectTypeEnum[]) => void;
  onSelectedItemUpdate: (newSelectedItem: AskIgorMenuItemEnum) => void;
  onSubmit: (
    newSelectedItem: AskIgorMenuItemEnum,
    text: string,
    dataPointsAmount?: number,
    requirements?: TAskIgorRequirement[]
  ) => void;
  loading: boolean;
  onEmptyDocumentList: () => void;
  onSetAskIgorModalOptions: (documentId: string[]) => void;
  isGeneratingText: boolean;
  onFetchLinkedDocuments: (
    savedDocuments: ISavedDocumentDTO[] | undefined
  ) => void;
};

export const AskIgorMenu: FC<TAskIgorMenuProps> = ({
  object,
  selectedItem,
  selectedDocumentsOfIgor,
  defaultInput,
  selectedSavedDocumentsFormList,
  onSelectedItemUpdate,
  onDocumentTypesChange,
  onSubmit,
  loading,
  onEmptyDocumentList,
  onSetAskIgorModalOptions,
  isGeneratingText,
  onFetchLinkedDocuments,
}: TAskIgorMenuProps) => {
  // State
  const [documentTypes, setDocumentTypes] = useState<TAskIgorSettings[]>([
    { id: ObjectTypeEnum.Weblink, name: "Web", isChecked: false },
    { id: ObjectTypeEnum.ScienceArticle, name: "Science", isChecked: false },
    { id: ObjectTypeEnum.UsPatent, name: "Patents", isChecked: false },
  ]);
  const [inputValue, setInputValue] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("Report");
  const [highlightCount, setHighlightCount] = useState<number>(0);
  const [linkedHighlightCount, setLinkedHighlightCount] = useState<number>(0);
  const [savedDocuments, setSavedDocuments] =
  useState<ISavedDocumentDTO[] | undefined>(undefined);
  const [referenceElement, setReferenceElement] =
  useState<HTMLHeadElement | null>(null);
  const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);
  const [referenceTitleElement, setReferenceTitleElement] = useState<HTMLHeadElement | null>(null);
  const [isTitleTooltipShown, setIsTitleTooltipShown] = useState<boolean>(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [emptyDocumentList, setEmptyDocumentList] = useState<boolean>(false);

  // Context
  const { setAskIgorModalOptions } = useContext(EditorContext);

  // Memo
  const presetTabs = useMemo((): TTab[] => {
    // init settings tabs
    const tabs: TTab[] = [
      { name: "Report" },
      { name: "Extract information" },
      { name: "Other" },
    ];

    // return settings tabs
    return tabs;
  }, []);

  // Helpers
  const getDocumentIdsByType = (typeId: number): string[] => {
    return (
      savedDocuments
        ?.filter((doc) => {
          const docType = ObjectTypeHelperSingleton.documentTypeToObjectType(doc.savedDocumentType);
          return typeId === ObjectTypeEnum.UsPatent
            ? docType === ObjectTypeEnum.UsPatent || docType === ObjectTypeEnum.MagPatent
            : docType === typeId;
        })
        .map((doc) => doc.id) ?? []
    );
  };

  // sync the document types with the selection of the documents
  const onTypeSyncWithSelectedDocuments = () => {
    const hasDocumentType = (types: ObjectTypeEnum[]) =>
      savedDocuments?.some(
        (doc) =>
          selectedDocumentIds.includes(doc.id) &&
          types.includes(ObjectTypeHelperSingleton.documentTypeToObjectType(doc.savedDocumentType))
      ) || false; // Ensure it always returns a boolean
    
    setDocumentTypes((prevDocumentTypes) =>
      prevDocumentTypes.map((type) => {
        let isChecked = type.isChecked;
        if (type.id === ObjectTypeEnum.Weblink) {
          isChecked = hasDocumentType([ObjectTypeEnum.Weblink]);
        } else if (type.id === ObjectTypeEnum.ScienceArticle) {
          isChecked = hasDocumentType([ObjectTypeEnum.ScienceArticle]);
        } else if (type.id === ObjectTypeEnum.UsPatent) {
          isChecked = hasDocumentType([ObjectTypeEnum.MagPatent, ObjectTypeEnum.UsPatent]);
        }

        return { ...type, isChecked };
      })
    );

    const totalHighlightCount =
      savedDocuments
        ?.filter((doc) => selectedDocumentIds.includes(doc.id))
        .reduce(
          (acc, doc) => acc + (doc.linkedCounts?.highlightCount || 0),
          0
        ) || 0;

    setHighlightCount(totalHighlightCount + linkedHighlightCount);
    onSetAskIgorModalOptions(selectedDocumentIds);
    setAskIgorModalOptions((prevAskIgorModalOptions) => {
      return {
        ...prevAskIgorModalOptions,
        documentsSelected: selectedDocumentIds,
      };
    });
  };

  const onAskIgorMenuItemClick = (item: AskIgorMenuItemEnum): void => {
    onSubmit(item, inputValue);
  };

  // on the click of a type from the types menu
  const onDocumentTypesCheckboxChange = (typeId: number, isChecked: boolean) => {
    const documentIds = getDocumentIdsByType(typeId);

    setSelectedDocumentIds((prevIds) =>
      isChecked ? [...prevIds, ...documentIds] : prevIds.filter((id) => !documentIds.includes(id))
    );
  
    setDocumentTypes((prevDocumentTypes) =>
      prevDocumentTypes.map((type) =>
        type.id === typeId ? { ...type, isChecked } : type
      )
    );
  };

  // on the click of a document from the documents list
  const onSavedDocumentCheckboxChange = (documentId: string) => {
    setSelectedDocumentIds((prevIds) =>
      prevIds.includes(documentId)
        ? prevIds.filter((id) => id !== documentId)
        : [...prevIds, documentId]
    );
  };

  // get savedDocuments & counts of the linked documents and highlights
  const onGetDocuments = async () => {
    setInputValue(defaultInput ?? "");

    const linkedDocuments =
      await SavedDocumentControllerSingleton.getLinkedToObject(object.id);
    setSavedDocuments(linkedDocuments);

    onFetchLinkedDocuments(linkedDocuments);

    const getSelectedLinkedDocuments = (): ISavedDocumentDTO[] => {
      if (selectedSavedDocumentsFormList?.length) return selectedSavedDocumentsFormList;
      if (selectedDocumentsOfIgor.length > 0) {
        return (
          linkedDocuments?.filter((doc) =>
            selectedDocumentsOfIgor.includes(doc.id)
          ) ?? []
        );
      }
      return linkedDocuments ?? [];
    };

    const selectedLinkedDocuments = getSelectedLinkedDocuments();

    // Update document types with selected state
    selectedLinkedDocuments.forEach((doc) => {
      const type = ObjectTypeHelperSingleton.documentTypeToObjectType(
        doc.savedDocumentType
      );
      const documentType = documentTypes.find((t) => t.id === type);
      if (documentType) documentType.isChecked = true;
    });

    const preSelectedDocuments = (() => {
      if (selectedSavedDocumentsFormList?.length) {
      return selectedSavedDocumentsFormList.map((doc) => doc.id);
      } else if (selectedDocumentsOfIgor.length > 0) {
      return selectedDocumentsOfIgor;
      } else {
      return linkedDocuments?.map((doc) => doc.id) ?? [];
      }
    })();

    setSelectedDocumentIds(preSelectedDocuments);
    onSetAskIgorModalOptions(preSelectedDocuments);

    // Fetch and update highlight counts
    const linkedHighlights =
      await HighlightControllerSingleton.getLinkedToObject(object.id);
    setLinkedHighlightCount(linkedHighlights?.length ?? 0);

    const calculateTotalHighlightCount = (): number =>
      selectedLinkedDocuments.reduce(
        (acc, doc) => acc + (doc.linkedCounts?.highlightCount ?? 0),
        0
      );

    setHighlightCount(
      calculateTotalHighlightCount() + (linkedHighlights?.length ?? 0)
    );
    if (linkedDocuments?.length === 0 && linkedHighlights?.length === 0) {
      onEmptyDocumentList();
      setEmptyDocumentList(true);
    }
  };

  useEffect(() => {
    onGetDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onTypeSyncWithSelectedDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentIds]);

  useEffect(() => {
      onDocumentTypesChange(
        documentTypes
        .filter((type) => type.isChecked)
        .flatMap((type) =>
        (type.id === ObjectTypeEnum.UsPatent || type.id === ObjectTypeEnum.MagPatent)
          ? [ObjectTypeEnum.UsPatent, ObjectTypeEnum.MagPatent]
          : [type.id]
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypes]);

  const onSourcesContainerKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Enter") {
      setIsPopoverShown((prevState) => !prevState);
    }
  };

  const onMouseEnterHandler = (): void => {
    setIsTitleTooltipShown(true);
  };

  // Render
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FontAwesomeIcon icon={faMessageBot} />
        <p>Ask IGOR</p>
      </div>
      <div
        className={`${styles.optionsContainer} ${
          loading || emptyDocumentList ? styles.emptyDocumentList : ""
        }`}
      >
        <div className={styles.options}>
          <div className={styles.optionsTitle}>
            <FontAwesomeIcon
              icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                object.objectType
              )}
            />
            <h4
              ref={setReferenceTitleElement}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={() => setIsTitleTooltipShown(false)}
            >{object.name}</h4>
            <Tooltip
              referenceEl={referenceTitleElement}
              isOpen={isTitleTooltipShown}
              tooltipText={object.name}
            />
          </div>
          <div className={styles.sourcesContainer}>
            <div
              className={`${styles.sources} ${
                isPopoverShown ? styles.open : ""
              }`}
              ref={setReferenceElement}
              onClick={() => {
                setIsPopoverShown(!isPopoverShown);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={onSourcesContainerKeyDown}
            >
              <div className={styles.countContainer}>
                <div className={`${styles.count} ${styles.document}`}>
                  <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faFile} />
                  </div>
                  <h4>{selectedDocumentIds.length}</h4>
                </div>
                <div className={`${styles.count} ${styles.highlight}`}>
                  <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faHighlighter} />
                  </div>
                  <h4>{highlightCount}</h4>
                </div>
              </div>
              <FontAwesomeIcon
                className={styles.pointer}
                icon={isPopoverShown ? faChevronUp : faChevronDown}
              />
            </div>
            {documentTypes.map((label, index) => (
              <div
                key={label.id}
                className={`${styles.sources} ${
                  loading || emptyDocumentList ? styles.disabled : ""
                }`}
              >
                <Checkbox
                  onCheckboxChange={(isChecked: boolean) => {
                    onDocumentTypesCheckboxChange(label.id, isChecked);
                  }}
                  isChecked={label.isChecked}
                  theme={(emptyDocumentList || savedDocuments?.every(
                    (doc) => {
                      const docType = ObjectTypeHelperSingleton.documentTypeToObjectType(doc.savedDocumentType);
                      return docType !== label.id && !(label.id === ObjectTypeEnum.UsPatent && docType === ObjectTypeEnum.MagPatent);
                    }
                  )) ? "grayDisabled" : "blue"}
                />
                <FontAwesomeIcon icon={[faGlobe, faFlask, faAward][index]} className={styles.noPointer}/>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.options}>
          <div className={styles.optionsTitle}>
            <h4>Ask me anything about the linked sources</h4>
          </div>
          <div
            className={`${styles.textContainer} ${
              loading || emptyDocumentList ? styles.disabled : ""
            }`}
          >
            <textarea
              className={styles.questionBox}
              placeholder={
                "Write your question on the linked sources (e.g. What are the main technologies mentioned?)"
              }
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
            <FindestButton
              buttonType="primary"
              extraClassName={`${styles.questionSubmit} ${
                inputValue === "" ? styles.disabled : ""
              }`}
              leftIconName={faChevronRight}
              onClick={() => {
                onSubmit(AskIgorMenuItemEnum.QuestionAndAnswer, inputValue);
              }}
            />
          </div>
        </div>
        <div className={styles.options}>
          <div className={styles.optionsTitle}>
            <h4>Or pick a preset</h4>
          </div>
          <Tabs
            tabs={presetTabs}
            theme="blue"
            defaultSelectedTab={presetTabs[0].name}
            onSelectedTabChange={(tabName: string) => {
              setActiveTab(tabName);
            }}
          />
          {activeTab === "Report" &&
            <div
              className={`${styles.presetContainer} ${
                loading || emptyDocumentList ? styles.disabled : ""
              }`}
            >
              <div className={styles.active}>
                {FeatureToggleConstants.TIAutomation && emptyDocumentList && (
                  <AskIgorMenuItem
                    item={
                      AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
                    }
                    isSelected={
                      selectedItem ===
                        AskIgorMenuItemEnum.GeneralDescriptionUsingLinks ||
                      selectedItem ===
                        AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge ||
                      selectedItem === AskIgorMenuItemEnum.GeneralDescription
                    }
                    isGeneratingText={isGeneratingText}
                    onClick={onAskIgorMenuItemClick}
                  />
                )}
              </div>
              <AskIgorMenuItem
                item={AskIgorMenuItemEnum.WriteSection}
                isSelected={selectedItem === AskIgorMenuItemEnum.WriteSection}
                isDisabled={inputValue === ""}
                isGeneratingText={isGeneratingText}
                onClick={onAskIgorMenuItemClick}
              />
              {FeatureToggleConstants.TIAutomation && !emptyDocumentList && (
                <AskIgorMenuItem
                  item={
                    AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
                  }
                  isSelected={
                    selectedItem ===
                      AskIgorMenuItemEnum.GeneralDescriptionUsingLinks ||
                    selectedItem ===
                      AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
                  }
                  isGeneratingText={isGeneratingText}
                  onClick={onAskIgorMenuItemClick}
                />
              )}
              <AskIgorMenuItem
                item={AskIgorMenuItemEnum.GenerateReport}
                isSelected={selectedItem === AskIgorMenuItemEnum.GenerateReport}
                isGeneratingText={isGeneratingText}
                onClick={onAskIgorMenuItemClick}
              />
            </div>
          }
          {activeTab === "Extract information" &&
            <div
              className={`${styles.presetContainer} ${
                loading || emptyDocumentList ? styles.disabled : ""
              }`}
            >
              {FeatureToggleConstants.TIAutomation && (
                <AskIgorMenuItem
                  item={AskIgorMenuItemEnum.InformationExtraction}
                  isSelected={
                    selectedItem === AskIgorMenuItemEnum.InformationExtraction
                  }
                  isGeneratingText={isGeneratingText}
                  onClick={onSelectedItemUpdate}
                />
              )}
            </div>
          }
          {activeTab === "Other" &&
            <div
              className={`${styles.presetContainer} ${
                loading || emptyDocumentList ? styles.disabled : ""
              }`}
            >
              {FeatureToggleConstants.TIAutomation && (
                <AskIgorMenuItem
                  item={AskIgorMenuItemEnum.ExecutiveSummary}
                  isSelected={
                    selectedItem === AskIgorMenuItemEnum.ExecutiveSummary
                  }
                  isGeneratingText={isGeneratingText}
                  onClick={onAskIgorMenuItemClick}
                />
              )}
            </div>
          }
        </div>
      </div>
      {isPopoverShown && (
        <Popover
          referenceEl={referenceElement}
          extraClassName={styles.popover}
          placement="bottom-start"
          onClickOutside={() => {
            if (event && !referenceElement?.contains(event.target as Node)) {
              setIsPopoverShown(false);
            }
          }}
        >
          <h4>Linked documents</h4>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.findest.com/contributor-workflows-and-features/build-reports-with-ai#sources-used-per-task"
            className={styles.moreInformation}
          >
            More information about linked sources
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
          {savedDocuments?.map((doc) => (
            <div key={doc.id} className={styles.popoverItem}>
              <div className={styles.popoverItemIcon}>
                <Checkbox
                  isChecked={selectedDocumentIds.includes(doc.id)}
                  onCheckboxChange={() => {
                    onSavedDocumentCheckboxChange(doc.id);
                  }}
                  theme="blue"
                />
                <FontAwesomeIcon icon={faFile} />
              </div>
              <p>{doc.title}</p>
            </div>
          ))}
        </Popover>
      )}
    </div>
  );
};
