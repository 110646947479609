import {
  FC,
  useState,
  useRef,
  ChangeEvent,
  useEffect,
  useCallback,
} from "react";
import {
  FileInputButton,
  FindestButton,
  MoreActionsDropdownButton,
} from "Components";
import { ObjectTypeEnum } from "Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCirclePlus,
  faFileLines,
} from "@fortawesome/pro-regular-svg-icons";
import styles from "./attachment.module.scss";
import { useFileUpload } from "Hooks";
import { FileHelperSingleton } from "Helpers";
import { TSavedFileDTO } from "Types";

type TAttachmentProps = {
  onDeleteAttachmentProp?: () => void;
  onAddAttachmentProp?: (file: File) => void;
  attachmentHeader?: string;
  onPreviewFile?: () => void;
  isReplaceFileAllowed?: boolean;
  onDownloadAttachmentProp?: () => void;
  handleFileInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  moreActions?: string;
  attachment?: TSavedFileDTO;
  isDocumentAttachment?: boolean;
};

export const Attachment: FC<TAttachmentProps> = ({
  onDeleteAttachmentProp,
  onAddAttachmentProp,
  attachmentHeader,
  onPreviewFile,
  isReplaceFileAllowed = true,
  onDownloadAttachmentProp,
  handleFileInput,
  moreActions,
  attachment,
  isDocumentAttachment,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);

  // Refs
  const fileInput = useRef<HTMLInputElement>(null);
  const onUploadFromActions = useRef<string | undefined>(undefined);

  const replaceFile = useCallback(() => {
    if (isReplaceFileAllowed && fileInput.current) {
      fileInput.current.value = "";
      fileInput.current?.click();
    }
  }, [isReplaceFileAllowed]);

  useEffect(() => {
    if (onUploadFromActions.current !== moreActions) {
      onUploadFromActions.current = moreActions;
      if (moreActions === "upload") {
        replaceFile();
      }
    }
  }, [moreActions, replaceFile]);

  const onAddAttachment = async (file: File) => {
    if (!isDocumentAttachment) {
      setUploadedFile(file);
    }
    onAddAttachmentProp?.(file);
  };

  // Size and types of attachment constants
  const acceptedMIMETypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const { onFileInputChangeAsync } = useFileUpload({
    onFileSelectedAsync: onAddAttachment,
    fileSizeLimit: 52_428_800,
    acceptedMIMETypes,
  });

  const fileSizeLimit = 52_428_800;

  const onDownloadAttachment = () => {
    if (onDownloadAttachmentProp) {
      onDownloadAttachmentProp?.();
      return;
    }
    if (!uploadedFile) {
      return;
    }
    FileHelperSingleton.onDownloadFile(uploadedFile);
  };

  const onDeleteAttachment = () => {
    setUploadedFile(undefined);
    onDeleteAttachmentProp?.();
  };

  const onMoreOptionsClick = (action: string) => {
    if (action === "Replace file") {
      replaceFile();
    } else if (action === "Download file") {
      onDownloadAttachment();
    } else if (action === "Delete file") {
      onDeleteAttachment();
    }
  };

  return (
    <div className={styles.attachment}>
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileInput ?? onFileInputChangeAsync}
      />
      {uploadedFile || attachment ? (
        <>
          {attachmentHeader && <p>{attachmentHeader}</p>}
          <div className={styles.attachmentBar}>
            <FontAwesomeIcon icon={faFileLines} />
            <button
              className={`${styles.attachmentName} ${!onPreviewFile ? styles.nonClickable : ""}`}
              type="button"
              onClick={onPreviewFile}
            >
              {attachment
                ? `${attachment.title}.${attachment.fileExtension}`
                : uploadedFile?.name}
            </button>
            <MoreActionsDropdownButton
              objectId={""}
              objectType={ObjectTypeEnum.Unknown}
              extraClassNames={{
                dropdownButton: styles.dropdownButton,
                dropdownButtonHover: styles.dropdownButtonHover,
                attachmentOptionText: styles.attachmentOptionText,
                optionsPopover: styles.optionsPopover,
                optionsContainer: styles.optionsContainer,
              }}
              attachmentOptions={[
                "Replace file",
                "Download file",
                "Delete file",
              ]}
              onAttachmentAction={onMoreOptionsClick}
            />
          </div>
          {onPreviewFile && (
            <FindestButton title="Open file" onClick={onPreviewFile} />
          )}
        </>
      ) : (
        <>
          <div className={styles.attachmentBar}>
            <FontAwesomeIcon icon={faFileCirclePlus} />
            <p>Add file (PDF, docx, pptx), maximum file size 50MB</p>
          </div>
          <FileInputButton
            buttonText="Add file"
            fileSizeLimit={fileSizeLimit}
            acceptedMIMETypes={acceptedMIMETypes}
            onFileSelectedAsync={onAddAttachment}
            extraClassNames={{
              fileInputButton: styles.fileInputButton,
              optionText: styles.optionText,
            }}
          />
        </>
      )}
    </div>
  );
};
