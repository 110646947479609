import { EntityItem, ListHeader } from "Components/Shared";
import { IEntityDTO } from "Interfaces";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
// Styles
import { faUnlock } from "@fortawesome/pro-solid-svg-icons";
import { EntityConstants } from "Constants";
import {
  EntityControllerSingleton,
  LockControllerSingleton,
} from "Controllers";
import {
  ObjectTypeEnum,
  OrderByEnum,
  SortTypeEnum,
  ToastTypeEnum,
} from "Enums";
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
import { useCheckboxedList, useObjectReferenceModal } from "Hooks";
import entityLikeListStyles from "Styles/EntityLikeList/entityLikeList.module.scss";
import { TButtonDefinition, TEntitiesDTO } from "Types";
import styles from "../DeletedArchive/deletedArchive.module.scss";

export const LockedEntities: FC = () => {
  // State
  const [entities, setEntities] = useState<IEntityDTO[]>([]);
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
  const [totalEntityCount, setTotalEntityCount] = useState<number>(0);
  const [lastPaginationFromDates, setLastPaginationFromDates] = useState<
    Date[]
  >([]);

  // Refs
  const container = useRef<HTMLDivElement | null>(null);

  // Hooks
  const {
    selectedItems: selectedEntities,
    setSelectedItems: setSelectedEntities,
    areAllItemsSelected: areAllEntitiesSelected,
    isAnyItemSelected: isAnyEntitySelected,
    onSelectAllItems,
    onSelectItem,
  } = useCheckboxedList<IEntityDTO>(
    entities,
    "Entity",
    "Entities",
    (entity) => ({
      id: entity.id,
      type: "Entity",
      name: entity.title,
      objectType: ObjectTypeEnum.Entity,
    })
  );
  const {
    referenceModal,
    closeReferenceModal,
    referenceModalProps,
    setReferenceModalProps,
  } = useObjectReferenceModal({
    title: "Unlock",
    icon: faUnlock,
    onClick: () => {
      onUnlockButtonClick(referenceModalProps.id);
    },
  });

  const refreshEntitiesAsync = async (
    fromDate: Date | undefined,
    currentSortType: SortTypeEnum
  ): Promise<void> => {
    const orderByType =
      currentSortType === SortTypeEnum.Oldest
        ? OrderByEnum.Ascending
        : OrderByEnum.Descending;
    const newEntities: TEntitiesDTO =
      await EntityControllerSingleton.getLockedAsync(orderByType, fromDate);

    // set state variables
    setEntities(newEntities.entities);
    setTotalEntityCount(newEntities.totalEntitiesCount);

    // Scroll to top of the component
    container.current?.scrollTo({ top: 0 });
  };

  const onSelectAllCheckboxChange = (isChecked: boolean) => {
    onSelectAllItems(isChecked, entities);
  };

  const onEntityCheckboxChange = (isChecked: boolean, id: string): void => {
    const currentEntity = entities.find((entity) => entity.id === id);
    if (!currentEntity) {
      return;
    }
    onSelectItem(isChecked, currentEntity, id);
  };

  const onPaginatePreviousAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (lastPaginationFromDates && lastPaginationFromDates.length > 0) {
      const lastPaginationFromDate: Date | undefined =
        lastPaginationFromDates.pop();
      if (lastPaginationFromDates.length > 1) {
        if (lastPaginationFromDate) {
          fromDate = lastPaginationFromDate;
        }
      }
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update entities list
    await refreshEntitiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToPreviousLockedEntitiesPage");
  };

  const onPaginateNextAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (entities && entities.length > 0) {
      const lastPaginationFromDate: Date =
        entities[entities.length - 1].dateAdded;
      fromDate = lastPaginationFromDate;
      lastPaginationFromDates.push(fromDate);
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update entities list
    await refreshEntitiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToNextLockedEntitiesPage");
  };

  const updateSortTypeAsync = async (
    newSortType: SortTypeEnum
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    // safety-checks
    if (newSortType === sortType) {
      return;
    }

    // set new sort type
    setSortType(newSortType);

    // update entities list
    await refreshEntitiesAsync(undefined, newSortType);

    // reset selected entities
    setSelectedEntities([]);

    // log
    LogHelperSingleton.log("SortLockedEntities");
  };

  const onEntityClick = (entity: IEntityDTO): void => {
    setReferenceModalProps({
      isOpen: true,
      id: entity.id,
      type: ObjectTypeEnum.Entity,
      doIgnoreIsDeleted: true,
    });
  };

  const onUnlockButtonClick = useCallback(
    async (id: string | undefined): Promise<void> => {
      let selectedIds: string[];
      if (id) {
        selectedIds = [id];
      } else {
        // Get selected entity ids
        selectedIds = selectedEntities.map(
          (selectedEntity) => selectedEntity.id
        );
      }

      // Check if any entities are selected
      if (selectedIds.length === 0) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "No entities selected to unlock."
        );
        return;
      }

      // Unlock entities
      for (const selectedId of selectedIds) {
        const isSuccess = await LockControllerSingleton.updateObjectLock(
          selectedId,
          ObjectTypeEnum.Entity,
          false
        );

        // If not successful, show error toast
        if (!isSuccess) {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Failed to unlock entity."
          );
          return;
        }
      }

      // refresh entities list
      refreshEntitiesAsync(undefined, SortTypeEnum.Newest);

      // reset selected entities
      setSelectedEntities([]);

      // Close referenceModal if entity is restored from the modal
      if (id) {
        closeReferenceModal();
      }
    },
    [selectedEntities, setSelectedEntities, closeReferenceModal]
  );

  const studiesArchiveButtons = useMemo(() => {
    return [
      {
        title: "Unlock",
        onClick: () => {
          onUnlockButtonClick(undefined);
        },
        icon: faUnlock,
        className: styles.restoreButton,
      },
    ] as TButtonDefinition[];
  }, [onUnlockButtonClick]);

  useEffect(() => {
    refreshEntitiesAsync(undefined, SortTypeEnum.Newest);
  }, []);

  return (
    <div className={entityLikeListStyles.entityLikeContainer} ref={container}>
      <div className={styles.listItemsContainer}>
        <ListHeader
          isAllListItemsSelected={areAllEntitiesSelected}
          isAnyListItemSelected={isAnyEntitySelected}
          listItemCountInterval={EntityConstants.MAXIMUM_ENTITIES_TO_RETRIEVE}
          totalListItemCount={totalEntityCount}
          sortType={sortType}
          updateSortType={updateSortTypeAsync}
          onPaginateNext={onPaginateNextAsync}
          onPaginatePrevious={onPaginatePreviousAsync}
          onSelectAllCheckboxChange={onSelectAllCheckboxChange}
          buttonDefinitions={studiesArchiveButtons}
        />
        <div className={entityLikeListStyles.entityLikeItemsList}>
          {entities.map((entity: IEntityDTO) => {
            const isSelected =
              selectedEntities.find(
                (selectedEntity) => selectedEntity.id === entity.id
              ) !== undefined;
            return (
              <EntityItem
                key={`entityitem-${entity.id}`}
                entity={entity}
                isSelected={isSelected}
                onCheckboxChange={onEntityCheckboxChange}
                onItemClickOverride={onEntityClick}
              />
            );
          })}
        </div>
      </div>
      {referenceModal}
    </div>
  );
};
