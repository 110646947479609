// node_modules
import { FC, useEffect, useRef, useState } from "react";
// Components
import { LoadingStatusIndicator, SavedDocuments } from "Components/Shared";
// Types
import { TDocumentsDTO, TOption } from "Types";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";
// Controllers
import { SavedDocumentControllerSingleton } from "Controllers";
// Enums
import {
  LinkStatusEnum,
  OrderByEnum,
  SavedDocumentTypeEnum,
  SortTypeEnum,
} from "Enums";
// Styles
import styles from "./documents.module.scss";
// Helpers
import {
  DocumentTypeHelperSingleton,
  LogHelperSingleton,
  SavedFiltersHelperSingleton,
} from "Helpers";
import { EmptyDocuments } from "Components";

export const Documents: FC = () => {
  // State
  const [savedDocuments, setSavedDocuments] = useState<ISavedDocumentDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isThereAnyFiltersSelected, setIsThereAnyFiltersSelected] =
    useState<boolean>(false);
  const [totalSavedDocumentsCount, setTotalSavedDocumentsCount] =
    useState<number>(0);

  // Refs
  const container = useRef<HTMLDivElement>(null);

  // Logic
  useEffect(() => {
    // get saved filters in local storage
    const savedFilters: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[] =
      SavedFiltersHelperSingleton.getDocumentsFilters(LinkStatusEnum.NotLinked);

    (async () => {
      await refreshDocumentsAsync(undefined, savedFilters, SortTypeEnum.Newest);
    })();

    // log
    LogHelperSingleton.log("DisplayDocuments");
  }, []);

  const refreshDocumentsAsync = async (
    fromDate: Date | undefined,
    filterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
    sortType: SortTypeEnum
  ): Promise<void> => {
    const newDocuments: TDocumentsDTO =
      await SavedDocumentControllerSingleton.getAsync(
        sortType === SortTypeEnum.Oldest
          ? OrderByEnum.Ascending
          : OrderByEnum.Descending,
        fromDate,
        DocumentTypeHelperSingleton.getSelectedFilterOptions(filterOptions)
      );

    if (newDocuments?.documents) {
      setSavedDocuments(newDocuments.documents);
      setTotalSavedDocumentsCount(newDocuments.totalCount);
    } else {
      setSavedDocuments([]);
      setTotalSavedDocumentsCount(0);
    }
    setIsLoading(false);
    setIsThereAnyFiltersSelected(
      DocumentTypeHelperSingleton.getSelectedFilterOptions(filterOptions)
        .length > 0
    );

    // Scroll to top of the component
    container.current?.scrollTo({ top: 0 });
  };

  if (!isThereAnyFiltersSelected) {
    if (isLoading) return <LoadingStatusIndicator shouldCenter status={1} />;

    if (savedDocuments.length === 0) {
      return <EmptyDocuments route="Documents" />;
    }
  }

  // Render
  return (
    <div className={styles.documentsContainer} ref={container}>
      <SavedDocuments
        documents={savedDocuments}
        doUseSavedFilters={true}
        totalDocumentsCount={totalSavedDocumentsCount}
        refreshDocumentsAsync={refreshDocumentsAsync}
      />
    </div>
  );
};
