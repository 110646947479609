// React
import { faTrashRestore } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
// Enums
import {
  ObjectTypeEnum,
  OrderByEnum,
  SortTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Controllers
import { StudyControllerSingleton } from "Controllers";
// Types
import { TButtonDefinition, TStudiesDTO } from "Types";
// Interfaces
import { IStudyDTO, IUndeleteObjectsDTO } from "Interfaces";
// Styles
import entityLikeListStyles from "Styles/EntityLikeList/entityLikeList.module.scss";
import styles from "./deletedArchive.module.scss";
// Shared Components
import { ListHeader, StudyItem } from "Components";
// Custom hooks
import { useCheckboxedList, useObjectReferenceModal } from "Hooks";
// Constants
import { StudyConstants } from "Constants";
// Helpers
import {
  EditorHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";

export const DeletedStudiesArchive: FC = () => {
  // State
  const [studies, setStudies] = useState<IStudyDTO[]>([]);
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
  const [totalStudiesCount, setTotalStudiesCount] = useState<number>(0);
  const [lastPaginationFromDates, setLastPaginationFromDates] = useState<
    Date[]
  >([]);

  // Refs
  const container = useRef<HTMLDivElement | null>(null);

  // Hooks
  const {
    selectedItems: selectedStudies,
    setSelectedItems: setSelectedStudies,
    areAllItemsSelected: areAllStudiesSelected,
    isAnyItemSelected: isAnyStudySelected,
    onSelectAllItems,
    onSelectItem,
  } = useCheckboxedList<IStudyDTO>(studies, "Study", "Studies", (study) => ({
    id: study.id,
    type: "Study",
    name: study.title,
    objectType: ObjectTypeEnum.Study,
    description: study.description,
  }));
  const {
    referenceModal,
    closeReferenceModal,
    referenceModalProps,
    setReferenceModalProps,
  } = useObjectReferenceModal({
    title: "Restore",
    icon: faTrashRestore,
    onClick: () => {
      onRestoreButtonClick(
        referenceModalProps.id,
        studies.find((study) => study.id === referenceModalProps.id)
          ?.description
      );
    },
  });

  const refreshStudiesAsync = async (
    fromDate: Date | undefined,
    currentSortType: SortTypeEnum
  ): Promise<void> => {
    const orderByType =
      currentSortType === SortTypeEnum.Oldest
        ? OrderByEnum.Ascending
        : OrderByEnum.Descending;
    const newStudies: TStudiesDTO =
      await StudyControllerSingleton.getDeletedAsync(orderByType, fromDate);

    // set state variables
    setStudies(newStudies.studies);
    setTotalStudiesCount(newStudies.totalStudiesCount);

    // Scroll to top of the component
    container.current?.scrollTo({ top: 0 });
  };

  const onSelectAllCheckboxChange = (isChecked: boolean) => {
    onSelectAllItems(isChecked, studies);
  };

  const onStudyCheckboxChange = (isChecked: boolean, id: string): void => {
    const currentStudy = studies.find((study) => study.id === id);
    if (!currentStudy) {
      return;
    }
    onSelectItem(isChecked, currentStudy, id);
  };

  const onPaginatePreviousAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (lastPaginationFromDates && lastPaginationFromDates.length > 0) {
      const lastPaginationFromDate: Date | undefined =
        lastPaginationFromDates.pop();
      if (lastPaginationFromDates.length > 1) {
        if (lastPaginationFromDate) {
          fromDate = lastPaginationFromDate;
        }
      }
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update studies list
    await refreshStudiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToPreviousDeletedStudiesArchivePage");
  };

  const onPaginateNextAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (studies && studies.length > 0) {
      const lastPaginationFromDate: Date =
        studies[studies.length - 1].dateAdded;
      fromDate = lastPaginationFromDate;
      lastPaginationFromDates.push(fromDate);
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update studies list
    await refreshStudiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToNextDeletedStudiesArchivePage");
  };

  const updateSortTypeAsync = async (
    newSortType: SortTypeEnum
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    // safety-checks
    if (newSortType === sortType) {
      return;
    }

    // set new sort type
    setSortType(newSortType);

    // update studies list
    await refreshStudiesAsync(undefined, newSortType);

    // reset selected studies
    setSelectedStudies([]);

    // log
    LogHelperSingleton.log("SortDeletedStudiesArchive");
  };

  const onStudyClick = (entity: IStudyDTO): void => {
    setReferenceModalProps({
      isOpen: true,
      id: entity.id,
      type: ObjectTypeEnum.Study,
      doIgnoreIsDeleted: true,
    });
  };

  const onRestoreButtonClick = useCallback(
    async (id?: string, description?: string): Promise<void> => {
      const undeleteObjects: IUndeleteObjectsDTO = {
        objectsToUndelete: [],
      };
      if (id) {
        undeleteObjects.objectsToUndelete.push({
          id: id,
          contentAsPlainText:
            EditorHelperSingleton.getContentAsPlainText(description),
        });
      } else {
        undeleteObjects.objectsToUndelete = selectedStudies.map(
          (selectedStudy) => ({
            id: selectedStudy.id,
            contentAsPlainText: EditorHelperSingleton.getContentAsPlainText(
              selectedStudy.description
            ),
          })
        );
      }

      // Check if any studies are selected
      if (undeleteObjects.objectsToUndelete.length === 0) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "No studies selected to restore"
        );
        return;
      }

      // restore studies
      const isSuccess = await StudyControllerSingleton.undeleteAsync(
        undeleteObjects
      );

      // If not successful, show error toast
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to restore studies"
        );
        return;
      }

      // refresh entities list
      refreshStudiesAsync(undefined, SortTypeEnum.Newest);

      // reset selected studies
      setSelectedStudies([]);

      // Close referenceModal if study is restored from the modal
      if (id) {
        closeReferenceModal();
      }
    },
    [closeReferenceModal, selectedStudies, setSelectedStudies]
  );

  const studiesArchiveButtons = useMemo(() => {
    return [
      {
        title: "Restore",
        onClick: () => {
          onRestoreButtonClick(undefined);
        },
        icon: faTrashRestore,
        className: styles.restoreButton,
      },
    ] as TButtonDefinition[];
  }, [onRestoreButtonClick]);

  useEffect(() => {
    refreshStudiesAsync(undefined, SortTypeEnum.Newest);
  }, []);

  return (
    <div className={entityLikeListStyles.entityLikeContainer} ref={container}>
      {/* <div className={[entityLikeListStyles.entityLikeItems, entityLikeListStyles.archiveList].join(" ")}> */}
      <div className={styles.listItemsContainer}>
        <ListHeader
          isAllListItemsSelected={areAllStudiesSelected}
          isAnyListItemSelected={isAnyStudySelected}
          listItemCountInterval={StudyConstants.MAXIMUM_STUDIES_TO_RETRIEVE}
          totalListItemCount={totalStudiesCount}
          sortType={sortType}
          updateSortType={updateSortTypeAsync}
          onPaginateNext={onPaginateNextAsync}
          onPaginatePrevious={onPaginatePreviousAsync}
          onSelectAllCheckboxChange={onSelectAllCheckboxChange}
          buttonDefinitions={studiesArchiveButtons}
        />
        <div className={entityLikeListStyles.entityLikeItemsList}>
          {studies.map((study: IStudyDTO) => {
            const isSelected =
              selectedStudies.find(
                (selectedStudy) => selectedStudy.id === study.id
              ) !== undefined;
            return (
              <StudyItem
                key={`studyitem-${study.id}`}
                study={study}
                isSelected={isSelected}
                onCheckboxChange={onStudyCheckboxChange}
                onItemClickOverride={onStudyClick}
                hideMoreActionsDropdownButton
              />
            );
          })}
        </div>
      </div>
      {referenceModal}
    </div>
  );
};
