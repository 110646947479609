import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faMessageBot, faPen, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { FindestButton, FindestTextBox, TextArea, Modal, LoadingStatusIndicator } from "Components";
import { IQueryDTO } from "Interfaces";
import { LoadingStatusEnum, SynonymTypeEnum, ToastTypeEnum } from "Enums";
import { ActionObjectHelperSingleton, EnvironmentVariableHelperSingleton, LogHelperSingleton, SynonymsHelperSingleton, ToastHelperSingleton } from "Helpers";
import { QueryControllerSingleton } from "Controllers";
import { ErrorConstants } from "Constants";
import styles from "./nlqToAdvancedSettingsModal.module.scss";
import {
  TGeneratedFunctionsAndKeywordsDTO,
  TPrimaryAndSynonyms,
  TSynonymDTO,
  TErroredActionObject,
  TErroredKeyword,
  TParentType,
  TErroredSynonyms,
} from "Types";

type TNLQToAdvancedSettingsModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  initialNaturalLanguageQuery: string;
  setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
  query: IQueryDTO;
  selectAdvancedSettings: () => void;
};

export const NLQToAdvancedSettingsModal: FC<TNLQToAdvancedSettingsModalProps> = ({
  isOpen,
  setIsOpen,
  initialNaturalLanguageQuery,
  setQuery,
  query,
  selectAdvancedSettings,
}) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [naturalLanguageQuery, setNaturalLanguageQuery] = useState<string>(initialNaturalLanguageQuery);
  const [isNaturalLanguageQuerySectionCollapsed, setIsNaturalLanguageQuerySectionCollapsed] = useState<boolean>(false);
  const [generatedFunctionsAndKeywords, setGeneratedFunctionsAndKeywords] = useState<TGeneratedFunctionsAndKeywordsDTO | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [erroredActionObjectFields, setErroredActionObjectFields] = useState<TErroredActionObject[]>([]);
  const [erroredKeywords, setErroredKeywords] = useState<TErroredKeyword[]>([]);
  const [erroredSynonyms, setErroredSynonyms] = useState<TErroredSynonyms[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  useEffect(() => {
    setNaturalLanguageQuery(initialNaturalLanguageQuery);
  }, [initialNaturalLanguageQuery]);
  
  const hasErrors = useCallback(() => {
    return erroredActionObjectFields.length > 0 || erroredKeywords.length > 0 || erroredSynonyms.length > 0;
  }, [erroredActionObjectFields.length, erroredKeywords.length, erroredSynonyms.length]);
  
  const {
    A_FUNCTION_CAN_ONLY_HAVE_ONE_ACTION,
    A_FUNCTION_NEEDS_ONE_ACTION_AND_AT_LEAST_ONE_OBJECT,
    ACTION_OBJECT_ALREADY_EXISTS, 
    A_KEYWORD_CAN_NOT_BE_EMPTY,
    KEYWORD_ALREADY_EXISTS,
    A_SYNONYM_CAN_NOT_BE_EMPTY,
    AN_ACTION_SYNONYM_CAN_NOT_HAVE_MORE_THAN_ONE_WORD,
    SYNONYM_ALREADY_EXISTS
  } = ErrorConstants;

  const renderError = (errorText: string) => {
    return (
      <div className={styles.errorContainer}>
        <FontAwesomeIcon icon={faExclamationCircle} />
        <p className={styles.errorMessage}>{errorText}</p>
      </div>
    );
  };

  const updateFieldSynonyms = (synoymsParam: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number): void => {
    SynonymsHelperSingleton.updateFieldSynonyms(synoymsParam, currentSynonymsType, currentFieldId, setQuery);
  };

  const addSelectedFunctionsAndKeywords = async () => {
    if (hasErrors()) {
      ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please fix the errors before proceeding.");
      return;
    }
  
    if (!generatedFunctionsAndKeywords) {
      return;
    }

    const isValidationComplete = [];
    for (const func of generatedFunctionsAndKeywords.functions) {
      const isFunctionValidationsComplete = validateActionObject(generatedFunctionsAndKeywords.functions.indexOf(func), func.action.primary, func.object.primary);
      isValidationComplete.push(isFunctionValidationsComplete);
    }
    for (const keyword of generatedFunctionsAndKeywords.keywords) {
      const isKeywordValidationsComplete = validateKeyword(generatedFunctionsAndKeywords.keywords.indexOf(keyword), keyword.primary);
      isValidationComplete.push(isKeywordValidationsComplete);
    }
    if (isValidationComplete.includes(false) || hasErrors()) {
      ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please fix the errors before proceeding.");
      return;
    }

    setIsAdding(true);

    LogHelperSingleton.log("TurnIntoAdvancedSearchQueryAddIntoAdvancedSettings");
  
    await addKeywords(generatedFunctionsAndKeywords);
    await addFunctions(generatedFunctionsAndKeywords);
  
    selectAdvancedSettings();
    onCloseModal();
  };
  
  const addKeywords = async (currentGeneratedFunctionsAndKeywords: TGeneratedFunctionsAndKeywordsDTO) => {
    for (const keyword of currentGeneratedFunctionsAndKeywords.keywords) {
      const createdKeyword = await EnvironmentVariableHelperSingleton.addEnvironmentVariableAsync(
        keyword.primary.trim(),
        query,
        setQuery
      );
  
      if (createdKeyword) {
        await addSynonyms(keyword.synonyms, SynonymTypeEnum.Environment, createdKeyword.id);
      }
    }
  };
  
  const addFunctions = async (currentGeneratedFunctionsAndKeywords: TGeneratedFunctionsAndKeywordsDTO) => {
    for (const func of currentGeneratedFunctionsAndKeywords.functions) {
      const createdActionObject = await ActionObjectHelperSingleton.addActionObjectAsync(
        func.action.primary.trim(),
        func.object.primary.trim(),
        query,
        setQuery
      );
  
      if (createdActionObject) {
        await addSynonyms(func.action.synonyms, SynonymTypeEnum.Action, createdActionObject.id);
        await addSynonyms(func.object.synonyms, SynonymTypeEnum.Object, createdActionObject.id);
      }
    }
  };
  
  const addSynonyms = async (synonyms: string[], type: SynonymTypeEnum, parentId: number) => {
    const addedSynonyms: TSynonymDTO[] = [];
    for (const synonym of synonyms) {
      const createdSynonym = await SynonymsHelperSingleton.addSynyonymAsync(
        query.guid,
        synonym.trim(),
        type,
        parentId,
        addedSynonyms,
        updateFieldSynonyms
      );
      if (createdSynonym) {
        addedSynonyms.push(createdSynonym);
      }
    }
  };

  const isActionValueValid = (searchSubTermUpdatedValue: string): boolean => {
    if (searchSubTermUpdatedValue.trim().split(" ").length > 1) {
      return false;
    }
    return true;
  };

  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNaturalLanguageQuery(e.target.value);
  };

  const onGenerateButtonClick = async () => {
    if (isLoading) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please wait for the current operation to complete."
      );
      return;
    }

    if (!naturalLanguageQuery) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please fill a natural language query."
      );
      return;
    }
    setIsLoading(true);
    const result: TGeneratedFunctionsAndKeywordsDTO | null =
      await QueryControllerSingleton.generateFunctionsAndKeywordsFromNLQAsync(naturalLanguageQuery);

    if (!result) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not generate functions and keywords."
      );
      return;
    }

    setGeneratedFunctionsAndKeywords(result);
    setIsLoading(false);
    setIsNaturalLanguageQuerySectionCollapsed(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setIsAdding(false);
    setErroredActionObjectFields([]);
    setErroredKeywords([]);
    setErroredSynonyms([]);
    setGeneratedFunctionsAndKeywords(null);
    setIsNaturalLanguageQuerySectionCollapsed(false);
    setNaturalLanguageQuery(initialNaturalLanguageQuery);
  };

  const onSynonymDelete = (parentIndex: number, synonymIndex: number, type: TParentType) => {
    const filterSynonyms = (item: TPrimaryAndSynonyms, index: number) => {
      if (index === parentIndex) {
        return {
          ...item,
          synonyms: item.synonyms.filter((_: string, idx: number) => idx !== synonymIndex),
        };
      }
      return item;
    };
  
    setGeneratedFunctionsAndKeywords((prev) => {
      if (!prev) return prev;
      if (type === "keyword") {
        const updatedKeywords = prev.keywords.map(filterSynonyms);
        return { ...prev, keywords: updatedKeywords };
      } else {
        const updatedFunctions = prev.functions.map((func, index) => {
          if (type === "action") {
            return { ...func, action: filterSynonyms(func.action, index) };
          } else {
            return { ...func, object: filterSynonyms(func.object, index) };
          }
        });
        return { ...prev, functions: updatedFunctions };
      }
    });
  
    setErroredSynonyms((prev) => {
      const filteredSynonyms = prev.filter((field) => !(field.parentType === type && field.parentIndex === parentIndex && field.index === synonymIndex));
      return filteredSynonyms.map((field) => ({
        ...field,
        index: field.parentIndex === parentIndex && field.parentType === type && field.index > synonymIndex ? field.index - 1 : field.index,
      }));
    });
  };

  const onActionObjectDelete = (index: number) => {
    setGeneratedFunctionsAndKeywords((prev) => {
      if (!prev) return prev;
      const updatedFunctions = [...prev.functions];
      updatedFunctions.splice(index, 1);
      return { ...prev, functions: updatedFunctions };
    });

    // if there is an error for this action object, remove it
    setErroredActionObjectFields((prev) => {
      const filteredFields = prev.filter((field) => field.actionObjectIndex !== index);
      return filteredFields.map((field) => ({
        ...field,
        actionObjectIndex: field.actionObjectIndex > index ? field.actionObjectIndex - 1 : field.actionObjectIndex,
      }));
    });

    // if there is an error for this action object's synonyms, remove them
    setErroredSynonyms((prev) => {
      return prev.filter((field) => !((field.parentType === "action" && field.parentIndex === index) || (field.parentType === "object" && field.parentIndex === index)))
        .map((f) => ({ ...f, parentIndex: (f.parentType === "action" || f.parentType === "object") && f.parentIndex > index ? f.parentIndex - 1 : f.parentIndex }));
    });
  };

  const onKeywordDelete = (index: number) => {
    setGeneratedFunctionsAndKeywords((prev) => {
      if (!prev) return prev;
      const updatedKeywords = [...prev.keywords];
      updatedKeywords.splice(index, 1);
      return { ...prev, keywords: updatedKeywords };
    });

    // if there is an error for this keyword, remove it
    setErroredKeywords((prev) => {
      const filteredKeywords = prev.filter((field) => field.keywordIndex !== index);
      return filteredKeywords.map((field) => ({
        ...field,
        keywordIndex: field.keywordIndex > index ? field.keywordIndex - 1 : field.keywordIndex,
      }));
    });

    // if there is an error for this keyword's synonyms, remove them
    setErroredSynonyms((prev) => {
      return prev.filter((field) => !(field.parentType === "keyword" && field.parentIndex === index))
        .map((f) => ({
          ...f,
          parentIndex: f.parentType === "keyword" && f.parentIndex > index ? f.parentIndex - 1 : f.parentIndex,
        }));
    });
  };

  const updateGeneratedFunctions = (index: number, key: "action" | "object", value: string) => {
    setGeneratedFunctionsAndKeywords((prev) => {
      if (!prev) return prev;
      const updatedFunctions = [...prev.functions];
      updatedFunctions[index][key].primary = value;
      return { ...prev, functions: updatedFunctions };
    });
  };

  const onUpdateSynonym = (parentIndex: number, synonymIndex: number, value: string, type: TParentType) => {
    setGeneratedFunctionsAndKeywords((prev) => {
      if (!prev) return prev;
      if (type === "keyword") {
        const updatedKeywords = [...prev.keywords];
        updatedKeywords[parentIndex].synonyms[synonymIndex] = value;
        return { ...prev, keywords: updatedKeywords };
      } else {
        const updatedFunctions = [...prev.functions];
        if (type === "action") {
          updatedFunctions[parentIndex].action.synonyms[synonymIndex] = value;
        } else {
          updatedFunctions[parentIndex].object.synonyms[synonymIndex] = value;
        }
        return { ...prev, functions: updatedFunctions };
      }
    });
  };

  const onUpdateKeyword = (index: number, value: string) => {
    setGeneratedFunctionsAndKeywords((prev) => {
      if (!prev) return prev;
      const updatedKeywords = [...prev.keywords];
      updatedKeywords[index].primary = value;
      return { ...prev, keywords: updatedKeywords };
    });
  };

  const validateActionObject = (index: number, action: string, object: string): boolean => {
    // if any of the fields are empty
    const isActionEmpty = !action.trim();
    const isObjectEmpty = !object.trim();
    const isEmpty = isActionEmpty || isObjectEmpty;
    if (isEmpty) {
      setErroredActionObjectFields((prev) => {
        const existingIndex = prev.findIndex((field) => field.actionObjectIndex === index);
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = { isActionErrored: isActionEmpty, isObjectErrored: isObjectEmpty, actionObjectIndex: index, errorText: A_FUNCTION_NEEDS_ONE_ACTION_AND_AT_LEAST_ONE_OBJECT };
          return updatedFields;
        } else {
          return [...prev, { isActionErrored: isActionEmpty, isObjectErrored: isObjectEmpty, actionObjectIndex: index, errorText: A_FUNCTION_NEEDS_ONE_ACTION_AND_AT_LEAST_ONE_OBJECT }];
        }
      });
      return false;
    }

    // if action is not valid
    if (!isActionValueValid(action)) {
      // setErroredActionObjectFields
      setErroredActionObjectFields((prev) => {
        const existingIndex = prev.findIndex((field) => field.actionObjectIndex === index);
        const newErrorObject = { isActionErrored: true, isObjectErrored: false, actionObjectIndex: index, errorText: A_FUNCTION_CAN_ONLY_HAVE_ONE_ACTION };
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = newErrorObject;
          return updatedFields;
        } else {
          return [...prev, newErrorObject];
        }
      });
      return false;
    }

    // if action object already exists in the query
    const isActionObjectAlreadyExists = query.actionObjects.some((actionObject) => {
      return actionObject.action === action && actionObject.dobject === object;
    });
    if (isActionObjectAlreadyExists) {
      setErroredActionObjectFields((prev) => {
        const existingIndex = prev.findIndex((field) => field.actionObjectIndex === index);
        const newErrorObject = { isActionErrored: true, isObjectErrored: true, actionObjectIndex: index, errorText: ACTION_OBJECT_ALREADY_EXISTS };
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = newErrorObject;
          return updatedFields;
        } else {
          return [...prev, newErrorObject];
        }
      });
      return false;
    }

    // if action object already exists in the list
    const isActionObjectAlreadyExistsInList = generatedFunctionsAndKeywords?.functions.some((func, idx) => {
      return idx !== index && func.action.primary === action && func.object.primary === object;
    });
    if (isActionObjectAlreadyExistsInList) {
      setErroredActionObjectFields((prev) => {
        const existingIndex = prev.findIndex((field) => field.actionObjectIndex === index);
        const newErrorObject = { isActionErrored: true, isObjectErrored: true, actionObjectIndex: index, errorText: ACTION_OBJECT_ALREADY_EXISTS };
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = newErrorObject;
          return updatedFields;
        } else {
          return [...prev, newErrorObject];
        }
      });
      return false;
    }

    // if there is an error for this action object, remove it
    setErroredActionObjectFields((prev) => {
      return prev.filter((field) => field.actionObjectIndex !== index);
    });
    return true;
  };

  const validateKeyword = (index: number, text: string): boolean => {
    // if keyword is empty
    const isEmpty = EnvironmentVariableHelperSingleton.isKeywordEmpty(text);
    if (isEmpty) {
      setErroredKeywords((prev) => {
        const existingIndex = prev.findIndex((field) => field.keywordIndex === index);
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = { keywordIndex: index, errorText: A_KEYWORD_CAN_NOT_BE_EMPTY };
          return updatedFields;
        } else {
          return [...prev, { keywordIndex: index, errorText: A_KEYWORD_CAN_NOT_BE_EMPTY }];
        }
      });
      return false;
    }

    // if keyword already exists
    const isKeywordAlreadyExists = query.environmentVariables.some((envVariable) => envVariable.environment === text);
    if (isKeywordAlreadyExists) {
      setErroredKeywords((prev) => {
        const existingIndex = prev.findIndex((field) => field.keywordIndex === index);
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = { keywordIndex: index, errorText: KEYWORD_ALREADY_EXISTS };
          return updatedFields;
        } else {
          return [...prev, { keywordIndex: index, errorText: KEYWORD_ALREADY_EXISTS }];
        }
      });
      return false;
    }

    // if keyword already exists in the list
    const isKeywordAlreadyExistsInList = generatedFunctionsAndKeywords?.keywords.some((keyword, idx) => {
      return idx !== index && keyword.primary === text;
    });
    if (isKeywordAlreadyExistsInList) {
      setErroredKeywords((prev) => {
        const existingIndex = prev.findIndex((field) => field.keywordIndex === index);
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = { keywordIndex: index, errorText: KEYWORD_ALREADY_EXISTS };
          return updatedFields;
        } else {
          return [...prev, { keywordIndex: index, errorText: KEYWORD_ALREADY_EXISTS }];
        }
      });
      return false;
    }

    // if there is an error for this keyword, remove it
    setErroredKeywords((prev) => {
      return prev.filter((field) => field.keywordIndex !== index);
    });
    return true;
  };

  const validateSynonym = (parentIndex: number, synonymIndex: number, value: string, type: TParentType) => {
    const isSynonymEmpty = SynonymsHelperSingleton.isSynonymValueEmpty(value);
    if (isSynonymEmpty) {
      setErroredSynonyms((prev) => {
        const existingIndex = prev.findIndex((field) => field.parentType === type && field.parentIndex === parentIndex && field.index === synonymIndex);
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = { parentType: type, parentIndex: parentIndex, index: synonymIndex, errorText: A_SYNONYM_CAN_NOT_BE_EMPTY };
          return updatedFields;
        } else {
          return [...prev, { parentType: type, parentIndex: parentIndex, index: synonymIndex, errorText: A_SYNONYM_CAN_NOT_BE_EMPTY }];
        }
      });
      return;
    }
  
    let existingList: string[] | undefined = undefined;
    if (type === "keyword") {
      existingList = generatedFunctionsAndKeywords?.keywords[parentIndex].synonyms;
    } else if (type === "action") {
      existingList = generatedFunctionsAndKeywords?.functions[parentIndex]["action"]["synonyms"];
    } else {
      existingList = generatedFunctionsAndKeywords?.functions[parentIndex]["object"]["synonyms"];
    }

    const isSynonymAlreadyExistsInList = existingList?.some((synonym, idx) => {
      return idx !== synonymIndex && synonym === value;
    });
    if (isSynonymAlreadyExistsInList) {
      setErroredSynonyms((prev) => {
        const existingIndex = prev.findIndex((field) => field.parentType === type && field.parentIndex === parentIndex && field.index === synonymIndex);
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = { parentType: type, parentIndex: parentIndex, index: synonymIndex, errorText: SYNONYM_ALREADY_EXISTS };
          return updatedFields;
        } else {
          return [...prev, { parentType: type, parentIndex: parentIndex, index: synonymIndex, errorText: SYNONYM_ALREADY_EXISTS }];
        }
      });
      return;
    }
  
    if (type === "action" && value.split(" ").length > 1) {
      setErroredSynonyms((prev) => {
        const existingIndex = prev.findIndex((field) => field.parentType === type && field.parentIndex === parentIndex && field.index === synonymIndex);
        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex] = { parentType: type, parentIndex: parentIndex, index: synonymIndex, errorText: AN_ACTION_SYNONYM_CAN_NOT_HAVE_MORE_THAN_ONE_WORD };
          return updatedFields;
        } else {
          return [...prev, { parentType: type, parentIndex: parentIndex, index: synonymIndex, errorText: AN_ACTION_SYNONYM_CAN_NOT_HAVE_MORE_THAN_ONE_WORD }];
        }
      });
      return;
    }
  
    setErroredSynonyms((prev) => {
      return prev.filter((field) => !(field.parentType === type && field.parentIndex === parentIndex && field.index === synonymIndex));
    });
  };

  return (
    <Modal
      extraClassNames={{ container: styles.nlqToAdvancedSettingsModal, header: styles.modalHeader }}
      isOpen={isOpen}
      onClose={onCloseModal}
      header="Turn simple search into advanced settings"
    >
      <div className={styles.modalBody}>
        {isNaturalLanguageQuerySectionCollapsed ? (
          <button type="button" className={styles.naturalLanguageQueryBar} onClick={() => setIsNaturalLanguageQuerySectionCollapsed(false)}>
            <div className={styles.naturalLanguageQueryDiv}>{naturalLanguageQuery}</div>
            <FontAwesomeIcon icon={faPen} />
          </button>
        ) : (
          <>
            <TextArea
              placeholder={
                "I am looking for a process to extract salt from seawater"
              }
              value={naturalLanguageQuery}
              onChange={onTextareaChange}
            />
            <div className={styles.footer}>
              <FindestButton
                extraClassName={styles.nlqToAdvancedButton}
                styleProps={["textTransformNone"]}
                leftIconName={faMessageBot}
                title="Generate functions and keywords"
                onClick={onGenerateButtonClick} />
              {isLoading && <LoadingStatusIndicator status={LoadingStatusEnum.Loading} />}
            </div>
          </>
        )}
        {!isLoading && generatedFunctionsAndKeywords && (
          <div className={styles.generatedFunctionsAndKeywords}>
            {generatedFunctionsAndKeywords.functions.length > 0 && (
              <>
                <h3>Functions</h3>
                <div>
                  {generatedFunctionsAndKeywords.functions.map((func, index) => {
                    const actionObjectKey = `actionObject_${index}`;
                    const relatedErroredActionObjectField = erroredActionObjectFields.find((field) => field.actionObjectIndex === index);
                    return (
                      <div className={`${styles.actionObjectContainer} ${hoveredItem === actionObjectKey ? styles.isHovered : ""}`} key={actionObjectKey}>
                        <div className={styles.actionObjectItemContainer}>
                          <div className={styles.actionObject}>
                            <div className={styles.halfWidth}>
                              <h4>Action</h4>
                              <div className={styles.actionContainer}>
                                <FindestTextBox
                                  value={func.action.primary}
                                  onChange={(text) => { updateGeneratedFunctions(index, "action", text); }}
                                  onBlur={() => { validateActionObject(index, func.action.primary, func.object.primary); }}
                                  isErrored={relatedErroredActionObjectField?.isActionErrored}
                                />
                              </div>
                            </div>
                            <div className={styles.halfWidth}>
                              <h4>Object</h4>
                              <div className={styles.objectContainer}>
                                <FindestTextBox
                                  value={func.object.primary}
                                  onChange={(text) => { updateGeneratedFunctions(index, "object", text); }}
                                  onBlur={() => { validateActionObject(index, func.action.primary, func.object.primary); }}
                                  isErrored={relatedErroredActionObjectField?.isObjectErrored}
                                />
                              </div>
                            </div>
                          </div>
                          {relatedErroredActionObjectField && renderError(relatedErroredActionObjectField.errorText)}
                          <div className={styles.actionObject}>
                            <div className={styles.halfWidth}>
                              {func.action.synonyms.length > 0 && (
                                <div className={styles.actionSynonymsContainer}>
                                  <h4>Synoynms</h4>
                                  <div className={styles.synonyms}>
                                    {func.action.synonyms.map((synonym, synonymIndex) => {
                                      const relatedErroredSynonym = erroredSynonyms.find((field) => field.parentType === "action" && field.parentIndex === index && field.index === synonymIndex);
                                      return (
                                        <div key={`action_${actionObjectKey}_${synonymIndex}`}>
                                          <div className={styles.synonymContainer}>
                                            <FindestTextBox
                                              value={synonym}
                                              onChange={(text) => { onUpdateSynonym(index, synonymIndex, text, "action"); }}
                                              onBlur={() => { validateSynonym(index, synonymIndex, synonym, "action"); }}
                                              isErrored={relatedErroredSynonym !== undefined}
                                            />
                                            <button type="button" className={styles.deleteItem} onClick={() => onSynonymDelete(index, synonymIndex, "action")} ><FontAwesomeIcon icon={faTrash} /></button>
                                          </div>
                                          {relatedErroredSynonym && renderError(relatedErroredSynonym.errorText)}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={styles.halfWidth}>
                              {func.object.synonyms.length > 0 && (
                                <div className={styles.objectSynonymsContainer}>
                                  <h4>Synoynms</h4>
                                  <div className={styles.synonyms}>
                                    {func.object.synonyms.map((synonym, synonymIndex) => {
                                      const relatedErroredSynonym = erroredSynonyms.find((field) => field.parentType === "object" && field.parentIndex === index && field.index === synonymIndex);
                                      return (
                                        <div key={`object${actionObjectKey}_${synonymIndex}`}>
                                          <div className={styles.synonymContainer}>
                                            <FindestTextBox
                                              value={synonym}
                                              onChange={(text) => { onUpdateSynonym(index, synonymIndex, text, "object"); }}
                                              onBlur={() => { validateSynonym(index, synonymIndex, synonym, "object"); }}
                                              isErrored={relatedErroredSynonym !== undefined}
                                            />
                                            <button type="button" className={styles.deleteItem} onClick={() => onSynonymDelete(index, synonymIndex, "object")}><FontAwesomeIcon icon={faTrash} /></button>
                                          </div>
                                          {relatedErroredSynonym && renderError(relatedErroredSynonym.errorText)}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          className={styles.deleteItem}
                          type="button"
                          onMouseEnter={() => setHoveredItem(actionObjectKey)}
                          onMouseLeave={() => setHoveredItem(null)}
                          onClick={() => { onActionObjectDelete(index); }}
                        ><FontAwesomeIcon icon={faTrash} /></button>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {generatedFunctionsAndKeywords.keywords.length > 0 && (
              <div className={styles.keywordsSectionContainer}>
                <h3>Keywords</h3>
                <div className={styles.keywordsContainer}>
                  {generatedFunctionsAndKeywords.keywords.map((keyword, index) => {
                    const keywordKey = `keyword_${index}`;
                    const relatedErroredKeyword = erroredKeywords.find((field) => field.keywordIndex === index);
                    return (
                      <div key={keywordKey} className={`${styles.keywordContainer} ${hoveredItem === keywordKey ? styles.isHovered : ""}`}>
                        <div className={styles.keyword}>
                          <FindestTextBox
                            value={keyword.primary}
                            onChange={(text) => { onUpdateKeyword(index, text); }}
                            onBlur={() => { validateKeyword(index, keyword.primary); }}
                            isErrored={relatedErroredKeyword !== undefined}
                          />
                          <button
                            className={styles.deleteItem}
                            type="button"
                            onMouseEnter={() => setHoveredItem(keywordKey)}
                            onMouseLeave={() => setHoveredItem(null)}
                            onClick={() => { onKeywordDelete(index); }}
                          ><FontAwesomeIcon icon={faTrash} /></button>
                        </div>
                        {relatedErroredKeyword && renderError(relatedErroredKeyword.errorText)}
                        {keyword.synonyms.length > 0 && (
                          <div className={styles.synonymsContainer}>
                            <h4>Synoynms</h4>
                            <div className={styles.synonyms}>
                              {keyword.synonyms.map((synonym, synonymIndex) => {
                                const relatedErroredSynonym = erroredSynonyms.find((field) => field.parentType === "keyword" && field.parentIndex === index && field.index === synonymIndex);
                                return (
                                  <div key={`${keywordKey}_${synonymIndex}`}>
                                    <div className={styles.synonymContainer}>
                                      <FindestTextBox
                                        value={synonym}
                                        onChange={(text) => { onUpdateSynonym(index, synonymIndex, text, "keyword"); }}
                                        onBlur={() => { validateSynonym(index, synonymIndex, synonym, "keyword"); }}
                                        isErrored={relatedErroredSynonym !== undefined}
                                      />
                                      <button type="button" className={styles.deleteItem} onClick={() => { onSynonymDelete(index, synonymIndex, "keyword"); }}><FontAwesomeIcon icon={faTrash} /></button>
                                    </div>
                                    {relatedErroredSynonym && renderError(relatedErroredSynonym.errorText)}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {!isLoading && generatedFunctionsAndKeywords && (
        <div className={styles.footer}>
          <FindestButton isDisabled={isAdding || generatedFunctionsAndKeywords.functions.length + generatedFunctionsAndKeywords.keywords.length === 0} title="Add into advanced settings" onClick={addSelectedFunctionsAndKeywords} />
          <FindestButton isDisabled={isAdding} buttonType="cancel" title="Cancel" onClick={onCloseModal} />
          {isAdding && <LoadingStatusIndicator status={LoadingStatusEnum.Loading} />}
        </div>
      )}
    </Modal>
  );
};
