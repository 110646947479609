// node_modules
import {
  FC,
  useState,
  useEffect,
  useRef,
  useContext,
  ChangeEvent,
  useCallback,
  useMemo,
} from "react";
// Components
import { Attachment, FileUploadProgressIndicator } from "Components";
// Interfaces
import { IDocumentDetails } from "Interfaces";
// Types
import { TSavedFileDTO } from "Types";
// Providers
import { ElementVisibilityContext } from "Providers";
//Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import {
  ObjectTypeHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
  FileHelperSingleton,
} from "Helpers";
// Controllers
import { SavedFileControllerSingleton } from "Controllers";

// Component props type
type TDocumentAttachmentProps = {
  document: IDocumentDetails;
  moreActions: string;
  updateDocument: (has: boolean) => void;
  onCreateDocument: () => Promise<void>;
};

// Component
export const DocumentAttachment: FC<TDocumentAttachmentProps> = ({
  document: documentObject,
  moreActions,
  updateDocument,
  onCreateDocument
}) => {
  const fileSizeLimit = 52_428_800;
  // State
  const [attachment, setAttachment] = useState<TSavedFileDTO | undefined>(
    undefined
  );
  const [fileUploadProgress, setFileUploadProgress] = useState<
    number | undefined
  >(undefined);

  // Refs
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const prevDocumentRef = useRef<IDocumentDetails | null>(null);

  // Context
  const { canUserEdit } = useContext(ElementVisibilityContext);

  // Size and types of attachment constants
  const acceptedMIMETypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const onPreviewFile = () => {
    attachment?.url && window.open(attachment.url, "_blank");
  };

  const onDownloadAttachment = async () => {
    const attachmentUrl = attachment?.url;
    const attachmentTitle = attachment?.title;

    if (!attachmentUrl || !attachmentTitle) {
      return;
    }

    try {
      await FileHelperSingleton.onDownloadFileFromUrl(
        attachmentUrl,
        attachmentTitle
      );
      LogHelperSingleton.log("DocumentAttachmentDownloaded");
    } catch (error) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not download file."
      );
      return;
    }
  };

  const onAddAttachment = async (file: File): Promise<void> => {
    if (!documentObject || !canUserEdit) {
      return;
    }

    await onCreateDocument();

    abortControllerRef.current = new AbortController();

    // add an attachment to document
    const createdFile: TSavedFileDTO | undefined | null =
      await SavedFileControllerSingleton.createSavedFileUsingForm(
        file,
        file.name,
        documentObject.id,
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          documentObject.documentType
        ),
        setFileUploadProgress,
        abortControllerRef.current.signal
      );

    // safety-checks
    if (!createdFile) {
      // Check if upload was aborted
      if (createdFile === null) {
        return undefined;
      }

      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not attach file."
      );
      return undefined;
    }

    LogHelperSingleton.log("DocumentAttachmentUploaded");
    getAttachment();
  };

  const onDeleteAttachment = async (): Promise<void> => {
    if (!documentObject || !canUserEdit) {
      return;
    }

    if (attachment) {
      const createdFile = await SavedFileControllerSingleton.deleteAsync(
        attachment.id
      );

      // safety-checks
      if (!createdFile) {
        // Check if upload was aborted
        if (createdFile === null) {
          return undefined;
        }

        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not delete file."
        );
        return undefined;
      }
      LogHelperSingleton.log("DocumentAttachmentDeleted");
      getAttachment();
    } else {
      return;
    }
  };

  const getAttachment = useCallback(async () => {
    const linkedFiles = await SavedFileControllerSingleton.getLinkedToObject(
      documentObject.id
    );
    if (linkedFiles?.length) {
      setAttachment(linkedFiles[0]);
      updateDocument(true);
    } else {
      setAttachment(undefined);
      updateDocument(false);
    }
  }, [documentObject.id, updateDocument]);

  const handleFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!FileHelperSingleton.isFileUploaded(event)) {
      return;
    }

    // get file
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      // if component only accepts certain MIME types && check if the file size exceeds the limit
      if (
        !FileHelperSingleton.isFileFormatAccepted(file, acceptedMIMETypes) ||
        FileHelperSingleton.isFileSizeTooLarge(file, fileSizeLimit)
      ) {
        getAttachment();
        return;
      }

      attachment &&
        (await SavedFileControllerSingleton.deleteAsync(attachment.id));
      setAttachment(undefined);
      onAddAttachment(file);
    }
  };

  useEffect(() => {
    if (prevDocumentRef.current !== documentObject) {
      prevDocumentRef.current = documentObject;
      getAttachment();
    }
  }, [documentObject, getAttachment]);

  const isReplaceFileAllowed = useMemo(() => {
    if (!documentObject || !canUserEdit) {
      return false;
    }
    return true;
  }, [documentObject, canUserEdit]);

  return (
    <>
      <Attachment
        onPreviewFile={onPreviewFile}
        onDownloadAttachmentProp={onDownloadAttachment}
        onDeleteAttachmentProp={onDeleteAttachment}
        isReplaceFileAllowed={isReplaceFileAllowed}
        attachmentHeader="Full text file"
        handleFileInput={handleFileInput}
        onAddAttachmentProp={onAddAttachment}
        moreActions={moreActions}
        attachment={attachment}
        isDocumentAttachment
      />
      <FileUploadProgressIndicator fileUploadProgress={fileUploadProgress} />
    </>
  );
};
