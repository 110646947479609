// node_modules
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
  CreatedByAccount,
  DropdownButton,
  FindestButton,
  LoadingStatusIndicator,
  MainTitle,
  ObjectSearchPopupContent,
  Popover,
  PubSubConnectedObjects,
  QueryFields,
  Tabs,
  ToggleButton,
} from "Components";
import { QuerySearchResults } from "./QuerySearchResults";
import { TechnologySearchFields } from "./TechnologySearchFields/TechnologySearchFields";
// Constants
import {
  EventConstants,
  FeatureToggleConstants,
  GeneralConstants,
  QueryConstants,
  WebsocketFunctionNames,
} from "Constants";
// Controllers
import {
  QueryControllerSingleton,
  ReadDocumentsControllerSingleton,
} from "Controllers";
// Enums
import {
  LogFeatureNameEnum,
  ObjectTypeEnum,
  SearchQueryTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Contexts
import {
  AuthContext,
  PubSubContext,
  QueryContext,
  QueryViewOptionsProvider,
  WebsocketContext,
} from "Providers";
// Types
import {
  TDropdownButtonOption,
  TIdNameTypeObjectType,
  TQueryFiltersDTO,
  TTab,
} from "Types";
// Helpers
import {
  ConnectedObjectsHelperSingleton,
  DateHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  QuerySortOptionsHelperSingleton,
  ToastHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
// Hooks
import { useDocument, useObjectReferenceModal, useQuery } from "Hooks";
// Interfaces
import {
  IDocumentSearchResult,
  IExecuteQueryParams,
  IQueryDTO,
  IQuerySearchingState,
  ITechnologySearchResult,
} from "Interfaces";
// Styles
import styles from "./queryDetailsPage.module.scss";

type TQueryDetailsPage = {
  extraClassNames?: { container?: string };
  showGoBackButton?: boolean;
  onDelete: () => void;
  onDuplicateAsync: (duplicateQuery: IQueryDTO) => Promise<void>;
  onSearchStarted: (
    correlationId: string,
    type: SearchQueryTypeEnum,
    hasTechnologySearchResults?: boolean | null,
    abortController?: AbortController
  ) => void;
  onSearchCompleted: (hasTechnologySearchResults?: boolean | null) => void;
};

export const QueryDetailsPage: FC<TQueryDetailsPage> = ({
  extraClassNames,
  showGoBackButton = true,
  onDelete,
  onDuplicateAsync,
  onSearchStarted,
  onSearchCompleted,
}: TQueryDetailsPage) => {
  // Context
  const { webSocketController } = useContext(WebsocketContext);
  const { query, setQuery } = useContext(QueryContext);
  const { auth, isUserExternal } = useContext(AuthContext);
  const { pubSubHandler } = useContext(PubSubContext);

  // Hooks
  const navigate = useNavigate();
  const {
    startUSESearchAsync,
    startDocumentSearchAsync,
    startTechnologySearchAsync,
    cancelSearchAsync,
  } = useQuery();
  const {
    loadIsAlreadyReadOnDocumentResultsAsync,
    loadDocumentSearchResultMetadataAsync,
    sortDocuments,
  } = useDocument();

  // Custom Hooks
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

  // State
  const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);
  const [querySearchingState, setQuerySearchingState] =
    useState<IQuerySearchingState>({
      isRunningDocumentsSearch: false,
      isRunningTechSearch: false,
      isRunningTechSearchOnResults: false,
    });
  const [page, setPage] = useState<number>(1);
  const [queryElementReference, setQueryElementReference] =
    useState<HTMLDivElement | null>(null);
  const [searchQueryType, setSearchQueryType] = useState<SearchQueryTypeEnum>(
    query?.type ?? SearchQueryTypeEnum.USEOnScience
  );
  const isGroupedSearch = FeatureToggleConstants.GroupedSearch;
  const [isQueryShown, setIsQueryShown] = useState<boolean>(true);
  const [correlationId, setCorrelationId] =
    useState<string | undefined>(undefined);
  const [techSearchQueryTextAreaRef, setTechSearchQueryTextAreaRef] =
    useState<HTMLTextAreaElement | null>(null);
  const [technologySearchResults, setTechnologySearchResults] = useState<
    ITechnologySearchResult[]
  >([]);
  const [documentResults, setDocumentResults] = useState<
    IDocumentSearchResult[]
  >([]);
  const [filteredDocumentResults, setFilteredDocumentResults] = useState<
    IDocumentSearchResult[]
  >([]);
  const [useChatResponse, setUseChatResponse] =
    useState<string | undefined>(undefined);
  const [areReadResultsShown, setAreReadResultsShown] = useState<boolean>(true);

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const searchAmount = 50;
  const searchTypes: TTab[] = [
    { name: QueryConstants.SCIENCE_DOCUMENTS },
    { name: QueryConstants.PATENT_DOCUMENTS },
    { name: QueryConstants.TECHNOLOGY_OVERVIEW },
  ];

  const fromSearchTypeEnumToTabName = (
    searchType?: SearchQueryTypeEnum
  ): string => {
    switch (searchType) {
      case SearchQueryTypeEnum.UniverseScienceArticles:
      case SearchQueryTypeEnum.USEOnScience:
        return QueryConstants.SCIENCE_DOCUMENTS;
      case SearchQueryTypeEnum.UniversePatents:
      case SearchQueryTypeEnum.USEOnPatents:
        return QueryConstants.PATENT_DOCUMENTS;
      case SearchQueryTypeEnum.UniverseTechnologies:
        return QueryConstants.TECHNOLOGY_OVERVIEW;
      default:
        return QueryConstants.SCIENCE_DOCUMENTS;
    }
  };

  const isSearching = useMemo(() => {
    return (
      querySearchingState.isRunningDocumentsSearch ||
      querySearchingState.isRunningTechSearch ||
      querySearchingState.isRunningTechSearchOnResults
    );
  }, [querySearchingState]);

  const isExecuteQueryButtonDisabled = useMemo((): boolean => {
    return (
      isSearching ||
      (query?.type === SearchQueryTypeEnum.UniverseTechnologies &&
        !query?.naturalLanguageQuery)
    );
  }, [isSearching, query?.naturalLanguageQuery, query?.type]);

  const endSearch = useCallback(
    (isTechnologySearchOnResults?: boolean | null) => {
      setCorrelationId(undefined);
      setQuerySearchingState({
        isRunningDocumentsSearch: false,
        isRunningTechSearch: false,
        isRunningTechSearchOnResults: false,
      });
      onSearchCompleted(isTechnologySearchOnResults);
    },
    [onSearchCompleted]
  );

  useEffect(() => {
    if (!correlationId) {
      return;
    }

    const searchEndedListenName = `${WebsocketFunctionNames.SearchEnded}-${correlationId}`;
    webSocketController.addHandler(searchEndedListenName, endSearch);

    return () => {
      webSocketController.removeHandler(searchEndedListenName, endSearch);
    };
  }, [webSocketController, endSearch, correlationId]);

  useEffect(() => {
    if (!query) {
      return;
    }

    webSocketController.addHandler(
      WebsocketFunctionNames.SearchEnded,
      endSearch
    );

    return () => {
      webSocketController.removeHandler(
        WebsocketFunctionNames.SearchEnded,
        endSearch
      );
    };
  }, [webSocketController, endSearch, query]);

  useEffect(() => {
    setSearchQueryType(query?.type ?? SearchQueryTypeEnum.USEOnScience);
  }, [query?.type]);

  const onSearchQueryTypeUpdate = (newSearchQueryType: SearchQueryTypeEnum) => {
    if (!query) return;

    setQuery((prevQuery: IQueryDTO | undefined) => {
      if (!prevQuery) return prevQuery;

      return {
        ...prevQuery,
        type: newSearchQueryType,
      };
    });

    setSearchQueryType(newSearchQueryType);

    QueryControllerSingleton.updateAsync(query.guid, {
      ...query,
      type: newSearchQueryType,
    });
  };

  const onNaturalLanguageQueryUpdate = (newNaturalLanguageQuery: string) => {
    if (!query) return;

    setQuery((prevQuery: IQueryDTO | undefined) => {
      if (!prevQuery) return prevQuery;

      return {
        ...prevQuery,
        naturalLanguageQuery: newNaturalLanguageQuery,
      };
    });

    QueryControllerSingleton.updateAsync(query.guid, {
      ...query,
      naturalLanguageQuery: newNaturalLanguageQuery,
    });
  };

  const updateQueryFilters = (newQueryFilters: TQueryFiltersDTO) => {
    if (!query) return;
    setQuery((prevQuery: IQueryDTO | undefined) => {
      if (!prevQuery) return prevQuery;

      return {
        ...prevQuery,
        filters: newQueryFilters,
      };
    });

    QueryControllerSingleton.updateFiltersAsync(query.guid, newQueryFilters);
  };

  const executeUSEQueryAsync = async (
    executeQueryParam: IExecuteQueryParams
  ) => {
    if (!executeQueryParam.query.naturalLanguageQuery) {
      setQuerySearchingState({
        isRunningDocumentsSearch: false,
        isRunningTechSearch: false,
        isRunningTechSearchOnResults: false,
      });

      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please fill a natural language query."
      );

      return;
    }

    abortControllerRef.current = new AbortController();

    onSearchStarted(
      executeQueryParam.query.guid,
      executeQueryParam.searchQueryType,
      false,
      abortControllerRef.current
    );

    ToastHelperSingleton.showToast(ToastTypeEnum.Success, QueryConstants.STARTED_SEARCH);

    const response = await startUSESearchAsync({
      ...executeQueryParam,
      abortSignal: abortControllerRef.current.signal,
    });

    let newDocumentResults: IDocumentSearchResult[] = (
      response?.documents ?? []
    ).reduce(
      (accumulator, document) => {
        if (!accumulator.map.has(document.documentId)) {
          accumulator.map.set(document.documentId, true);
          accumulator.results.push(document);
        }
        return accumulator;
      },
      {
        map: new Map<string, boolean>(),
        results: [] as IDocumentSearchResult[],
      }
    ).results;

    setUseChatResponse(response?.response);
    setDocumentResults(newDocumentResults);
    setFilteredDocumentResults(newDocumentResults);

    if (abortControllerRef.current.signal.aborted) {
      ToastHelperSingleton.showToast(ToastTypeEnum.Information, QueryConstants.SEARCH_CANCELLED);
      return;
    }

    newDocumentResults = await loadDocumentSearchResultMetadataAsync(
      newDocumentResults
    );

    newDocumentResults = await loadIsAlreadyReadOnDocumentResultsAsync(
      newDocumentResults,
      await ReadDocumentsControllerSingleton.getMyAsync()
    );

    response?.documents?.forEach((document) => {
      newDocumentResults.forEach((newDocumentResult) => {
        if (document.documentId !== newDocumentResult.documentId) return;
        const { searchInformation } = newDocumentResult;
        const score = document.score ?? searchInformation?.score ?? 0;
        const impactFactor =
          document.citation_score?.toString() ??
          searchInformation?.impactFactor ??
          "0";

        if (searchInformation) {
          searchInformation.score = score;
          searchInformation.impactFactor = impactFactor;
        } else {
          newDocumentResult.searchInformation = {
            score,
            impactFactor,
            matchedTerms: [],
          };
        }
      });
    });

    const sortOption =
      QuerySortOptionsHelperSingleton.getQuerySortOptionsEnumFromSearchValue(
        executeQueryParam.sortQuery
      );

    setQuerySearchingState({
      isRunningDocumentsSearch: false,
      isRunningTechSearch: false,
      isRunningTechSearchOnResults: false,
    });

    setDocumentResults(sortDocuments(sortOption, newDocumentResults));
    setFilteredDocumentResults(sortDocuments(sortOption, newDocumentResults));
  };

  const executeQueryAsync = async (executeQueryParam: IExecuteQueryParams, isPagination?: boolean) => {
    !isPagination && setAreReadResultsShown(true);

    const currentSearchQueryType = executeQueryParam.searchQueryType;

    if (!executeQueryParam.isTechnologySearchOnResults) {
      onSearchQueryTypeUpdate(currentSearchQueryType);
    }

    setPage(executeQueryParam?.page);

    const isDocumentSearchType = [
      SearchQueryTypeEnum.UniverseScienceArticles,
      SearchQueryTypeEnum.UniversePatents,
      SearchQueryTypeEnum.USEOnScience,
      SearchQueryTypeEnum.USEOnPatents,
    ].includes(currentSearchQueryType);

    setQuerySearchingState({
      isRunningDocumentsSearch:
        isDocumentSearchType && !executeQueryParam.isTechnologySearchOnResults,
      isRunningTechSearch:
        currentSearchQueryType === SearchQueryTypeEnum.UniverseTechnologies &&
        !executeQueryParam.isTechnologySearchOnResults,
      isRunningTechSearchOnResults:
        executeQueryParam.isTechnologySearchOnResults ?? false,
    });

    if (executeQueryParam?.doShowSearchResults) {
      setIsQueryShown(false);
    }

    switch (currentSearchQueryType) {
      case SearchQueryTypeEnum.USEOnScience:
      case SearchQueryTypeEnum.USEOnPatents:
        await executeUSEQueryAsync(executeQueryParam);
        break;
      case SearchQueryTypeEnum.UniverseScienceArticles:
      case SearchQueryTypeEnum.UniversePatents:
        await startDocumentSearchAsync({ ...executeQueryParam, searchAmount });
        break;
      case SearchQueryTypeEnum.UniverseTechnologies:
        await startTechnologySearchAsync({
          ...executeQueryParam,
        });
        break;
      default:
        break;
    }
  };

  const handleNewQueryNameAsync = useCallback(
    async (newName: string): Promise<void> => {
      // safety-checks
      if (!newName || !query) {
        // do nothing
        return;
      }

      // set new query ame
      query.name = newName;

      // update query name
      const updatedQuery: IQueryDTO | undefined =
        await QueryControllerSingleton.updateAsync(query.guid, query);

      // safety-checks
      if (!updatedQuery) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to update query name"
        );
        // do nothing
        return;
      }

      // log
      LogHelperSingleton.log("UpdateQueryName");

      // set the query to the new query
      setQuery((prevQuery: IQueryDTO | undefined) => {
        // safety-checks
        if (!prevQuery) {
          // return the previous query
          return prevQuery;
        }

        // return the updated query
        return {
          ...prevQuery,
          name: updatedQuery.name,
        };
      });
    },
    [query, setQuery]
  );

  // debounce the handleNewQueryNameAsync function
  const debouncedHandleNewQueryNameAsync = useMemo(
    () =>
      debounce(
        handleNewQueryNameAsync,
        EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY
      ),
    [handleNewQueryNameAsync]
  );

  const deleteQueryAsync = useCallback(
    async (queryToDelete: IQueryDTO): Promise<void> => {
      // confirm with the user that they want to delete the query
      if (!confirm("Are you sure you want to delete the query?")) {
        // stop execution
        return;
      }

      // delete query
      const isSuccess: boolean = await QueryControllerSingleton.bulkDeleteAsync(
        [queryToDelete.guid]
      );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to delete the query."
        );
        // stop execution
        return;
      }

      // log
      LogHelperSingleton.log("RemoveQuery(ies)");

      // call on delete handler
      onDelete();
    },
    [onDelete]
  );

  const onDuplicateQueryAsync = useCallback(
    async (queryToDuplicate: IQueryDTO): Promise<void> => {
      // duplicate query
      const duplicatedQuery: IQueryDTO | undefined =
        await QueryControllerSingleton.duplicateAsync(queryToDuplicate.guid);

      // safety-checks
      if (!duplicatedQuery) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to duplicate the query."
        );
        // stop execution
        return;
      }

      // call on duplicate handler
      await onDuplicateAsync(duplicatedQuery);
    },
    [onDuplicateAsync]
  );

  const onClickOptionAsync = useCallback(
    async (optionName: TDropdownButtonOption): Promise<void> => {
      // safety-checks
      if (!query) {
        // stop execution, return
        return;
      }

      // if the option is "delete"
      if (optionName === "delete") {
        // delete the query
        await deleteQueryAsync(query);
      } else if (optionName === "duplicate") {
        await onDuplicateQueryAsync(query);
      }
    },
    [deleteQueryAsync, onDuplicateQueryAsync, query]
  );

  const cancelSearch = useCallback(
    async (isTechnologySearchOnResults: boolean) => {
      abortControllerRef.current.abort();

      setQuerySearchingState({
        isRunningDocumentsSearch: false,
        isRunningTechSearch: false,
        isRunningTechSearchOnResults: false,
      });

      if (!correlationId) return;

      cancelSearchAsync(
        correlationId,
        searchQueryType,
        isTechnologySearchOnResults
      );
    },
    [cancelSearchAsync, correlationId, searchQueryType]
  );

  // Logic
  if (!query) {
    return (
      <div className={styles.loadingIndicatorContainer}>
        <LoadingStatusIndicator size={60} status={1} />
      </div>
    );
  }

  const addObjectToQueryAsync = async (
    currentQuery: IQueryDTO | undefined,
    selectedLinkToObject: TIdNameTypeObjectType
  ): Promise<void> => {
    // if current query is not set
    if (!currentQuery) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
          selectedLinkToObject.objectType
        ).toLowerCase()} to query.`
      );
      // stop execution, return
      return;
    }

    // add object to query
    await ConnectedObjectsHelperSingleton.addObjectToObjectAsync(
      selectedLinkToObject,
      pubSubHandler,
      currentQuery.guid,
      ObjectTypeEnum.Query,
      LogFeatureNameEnum.AdvancedSearch
    );

    // set the query to the new query
    setQuery(currentQuery);

    // close modal
    setIsSavePopupOpen(false);
  };

  const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
    setReferenceModalProps((previousReferenceModalProps) => {
      return {
        ...previousReferenceModalProps,
        isOpen: true,
        id: objectId,
        type: objectType,
      };
    });
  };

  const onSelectedQueryTypeChange = (tabName: string) => {
    switch (tabName) {
      case QueryConstants.SCIENCE_DOCUMENTS:
        onSearchQueryTypeUpdate(SearchQueryTypeEnum.USEOnScience);
        break;
      case QueryConstants.PATENT_DOCUMENTS:
        onSearchQueryTypeUpdate(SearchQueryTypeEnum.USEOnPatents);
        break;
      case QueryConstants.TECHNOLOGY_OVERVIEW:
        onSearchQueryTypeUpdate(SearchQueryTypeEnum.UniverseTechnologies);
        break;
      default:
        break;
    }
  };

  const onSearchClickAsync = async (
    currentSearchQueryType: SearchQueryTypeEnum,
    showSearchResults: boolean,
    currentPage: number,
    isGroupedSearchParam?: boolean,
    filteredResultNames?: string[],
    groupName?: string,
    sortQuery?: string,
    isTechnologySearchOnResults?: boolean,
    documents?: IDocumentSearchResult[]
  ): Promise<void> => {
    await executeQueryAsync({
      query,
      page: currentPage,
      searchQueryType: currentSearchQueryType,
      doShowSearchResults: showSearchResults,
      doGroupDocumentSearchResults:
        isGroupedSearchParam ?? isGroupedSearch,
      filteredResultNames,
      groupName,
      sortQuery,
      isTechnologySearchOnResults,
      documents: documents
        ? documents.map((document) => {
          return {
            id: document.documentId,
            title: document.title,
            type: document.documentType,
            url: document.url,
          };
        })
        : [],
    });
  };

  const onPaginateAsync = async (
    isToNextPage: boolean,
    alreadyPagedNames: string[],
    sortQuery?: string
  ): Promise<void> => {
    await executeQueryAsync({
      query,
      page: isToNextPage ? page + 1 : page - 1,
      searchQueryType,
      doShowSearchResults: true,
      doGroupDocumentSearchResults: isGroupedSearch,
      filteredResultNames: alreadyPagedNames,
      sortQuery,
      isPaginate: true,
    }, true);
  };

  const focusOnTechSearchQueryTextArea = (): void => {
    if (techSearchQueryTextAreaRef) {
      techSearchQueryTextAreaRef.focus();
      techSearchQueryTextAreaRef.selectionStart =
        techSearchQueryTextAreaRef.value.length;
    }
  };

  const onCalculateSearchTermsCountAsync = async (): Promise<void> => {
    await executeQueryAsync({
      query,
      page: 1,
      searchQueryType,
      doShowSearchResults: false,
    });
  };

  const onExecuteQueryClickAsync = async (): Promise<void> => {
    await executeQueryAsync({
      query,
      page: 1,
      searchQueryType,
      doShowSearchResults: true,
    });
  };

  const onElementClickAsync = async (
    result: TIdNameTypeObjectType
  ): Promise<void> => {
    addObjectToQueryAsync(query, result);
  };

  const onBackToQueriesClick = (): void => {
    navigate("/queries");
  };

  const onConnectToObjectClick = (): void => {
    setIsSavePopupOpen(true);
  };

  const onClickOutside = (): void => {
    setIsSavePopupOpen(false);
  };

  if (!query) return null;

  // Render
  return (
    <div
      className={`${extraClassNames?.container ?? ""} ${styles.queryDetailsPageContainer
        }`}
    >
      <div className={styles.queryDetailsPageHeader}>
        <div className={styles.queryDetailsPageHeaderPart}>
          {showGoBackButton && (
            <FontAwesomeIcon
              className={styles.goBackToQueriesIcon}
              icon={faChevronLeft}
              onClick={onBackToQueriesClick}
            />
          )}
          <ToggleButton
            toggleTextLeft="Query"
            toggleTextRight="Results"
            leftToggleActive={isQueryShown}
            onClickToggleButton={setIsQueryShown}
            buttonWidth="180px"
          />
          <MainTitle
            title={query.name}
            isEditable={!isUserExternal}
            onUpdateTitle={debouncedHandleNewQueryNameAsync}
            extraClassName={styles.titleInput}
          />
        </div>
        <div className={styles.queryDetailsPageHeaderPart}>
          <DropdownButton
            isButtonEnabled={true}
            optionLabels={["duplicate", "delete"]}
            onClickOption={onClickOptionAsync}
            extraClassNames={{
              dropdownButton: styles.queryOptionsButton,
              optionText: styles.optionText,
            }}
            buttonText="options"
          />
          <CreatedByAccount
            email={query.username}
            createdDate={DateHelperSingleton.getDateWithYear(query.dateCreated)}
            userIconSize="large"
          />
        </div>
      </div>
      <div
        className={[
          styles.queryDetailsContainer,
          isQueryShown ? styles.active : "",
        ].join(" ")}
      >
        <PubSubConnectedObjects
          mainObjectId={query.guid}
          mainObjectType={ObjectTypeEnum.Query}
          connectedObjects={query.connectedObjects}
          onConnectToObjectClick={onConnectToObjectClick}
          setContainerElementReference={setQueryElementReference}
          extraClassName={styles.connectedObjectsContainer}
          disableConnectToNewObjectButton={
            UserHelperSingleton.isUserViewer(auth) ||
            UserHelperSingleton.isUserExternalByAuth(auth)
          }
        />
        <Popover
          referenceEl={queryElementReference}
          isOpen={isSavePopupOpen}
          onClickOutside={onClickOutside}
          extraClassName={styles.objectSearchPopupContainer}
          placement="bottom-start"
          exceptionDataIdentifiter={
            GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
          }
        >
          <ObjectSearchPopupContent
            currentObjectId={query.guid}
            onElementClick={onElementClickAsync}
            doShowRecentActivity={true}
            openReferenceModal={openReferenceModal}
          />
        </Popover>
        <Tabs
          tabs={searchTypes}
          extraClassNames={{ container: styles.tabsContainer, tab: styles.tab }}
          defaultSelectedTab={fromSearchTypeEnumToTabName(query.type)}
          onSelectedTabChange={onSelectedQueryTypeChange}
        />
        {query.type !== SearchQueryTypeEnum.UniverseTechnologies && (
          <QueryFields
            query={query}
            setQuery={setQuery}
            searchQueryType={searchQueryType}
            onSearchQueryTypeUpdate={onSearchQueryTypeUpdate}
            updateNaturalLanguageQuery={onNaturalLanguageQueryUpdate}
            tab={fromSearchTypeEnumToTabName(query.type)}
            isSearching={isSearching}
          />
        )}
        {query.type === SearchQueryTypeEnum.UniverseTechnologies && (
          <div className={styles.technologySearchFieldsContainer}>
            <TechnologySearchFields
              setTechSearchQueryTextAreaRef={setTechSearchQueryTextAreaRef}
              query={query}
              updateQueryFilters={updateQueryFilters}
              technologySearchResults={technologySearchResults}
              updateNaturalLanguageQuery={onNaturalLanguageQueryUpdate}
            />
          </div>
        )}
        <div className={styles.actionButtons}>
          <FindestButton
            title="Execute query"
            onClick={onExecuteQueryClickAsync}
            isDisabled={isExecuteQueryButtonDisabled}
          />
          {query.type !== SearchQueryTypeEnum.UniverseTechnologies && (
            <FindestButton
              title="Calculate search terms count"
              onClick={onCalculateSearchTermsCountAsync}
              buttonType={"secondary"}
              isDisabled={isSearching}
            />
          )}
          {isSearching && (
            <div className={styles.loadingIndicatorContainer}>
              <LoadingStatusIndicator size={32} status={1} />
              <FindestButton
                onClick={() => {
                  cancelSearch(
                    querySearchingState.isRunningTechSearchOnResults
                  );
                }}
                buttonType="secondary"
                title="Cancel search"
              />
            </div>
          )}
        </div>
      </div>
      <QueryViewOptionsProvider>
        <QuerySearchResults
          query={query}
          setQuery={setQuery}
          querySearchingState={querySearchingState}
          setQuerySearchingState={setQuerySearchingState}
          pageNumber={page}
          searchResultAmount={searchAmount}
          onPaginate={onPaginateAsync}
          isQueryShown={isQueryShown}
          searchQueryType={searchQueryType}
          setIsQueryShown={setIsQueryShown}
          onSearchQueryTypeUpdate={onSearchQueryTypeUpdate}
          onSearchClick={onSearchClickAsync}
          setCorrelationId={setCorrelationId}
          cancelSearch={() => {
            cancelSearch(querySearchingState.isRunningTechSearchOnResults);
          }}
          updateQueryFilters={updateQueryFilters}
          updateNaturalLanguageQuery={onNaturalLanguageQueryUpdate}
          onSearchStarted={onSearchStarted}
          focusOnTechSearchQueryTextArea={focusOnTechSearchQueryTextArea}
          technologySearchResults={technologySearchResults}
          setTechnologySearchResults={setTechnologySearchResults}
          documentResults={documentResults}
          setDocumentResults={setDocumentResults}
          filteredDocumentResults={filteredDocumentResults}
          setFilteredDocumentResults={setFilteredDocumentResults}
          useChatResponse={useChatResponse}
          setUseChatResponse={setUseChatResponse}
          areReadResultsShown={areReadResultsShown}
          setAreReadResultsShown={setAreReadResultsShown}
        />
      </QueryViewOptionsProvider>
      {referenceModal}
    </div>
  );
};
