// node_modules
import { ChangeEvent } from "react";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";
// Icons
import {
  faFile,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFileZipper,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";

export class FileHelper {
  public isAcceptedImageFileFormat(file: File): boolean {
    // init array of accepted image file formats
    const acceptedImageFileFormats = [
      "image/jpeg",
      "image/jpg",
      "image/bmp",
      "image/gif",
      "image/png",
      "image/svg+xml",
      "image/webp",
    ];

    // return true if the file is an accepted image file format
    return acceptedImageFileFormats.includes(file.type);
  }

  public getIconFromExtension(extension: string): IconDefinition {
    extension = extension.toLowerCase();
    switch (extension) {
      case "pdf":
        return faFilePdf;
      case "pptx":
      case "pptm":
      case "ppt":
        return faFilePowerpoint;
      case "xlsx":
      case "xlsm":
      case "xls":
        return faFileExcel;
      case "csv":
      case "tsv":
        return faFileCsv;
      case "docx":
      case "docm":
      case "doc":
        return faFileWord;
      case "zip":
      case "rar":
      case "7z":
        return faFileZipper;
      case "mp4":
      case "avi":
      case "mov":
      case "wmv":
      case "flv":
      case "mkv":
        return faFileVideo;
      case "mp3":
      case "wav":
      case "wma":
      case "ogg":
      case "flac":
        return faFileAudio;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
      case "tiff":
      case "svg":
        return faFileImage;
      case "txt":
      case "json":
        return faFileLines;
      case "js":
      case "ts":
      case "html":
      case "css":
      case "scss":
      case "less":
      case "xml":
      case "cs":
      case "java":
      case "py":
      case "php":
      case "c":
      case "cpp":
      case "h":
      case "hpp":
      case "go":
      case "swift":
      case "rb":
      case "sql":
      case "pl":
        return faFileCode;
      default:
        return faFile;
    }
  }

  public isFileUploaded(event: ChangeEvent<HTMLInputElement>): boolean {
    if (
      !event.target.files ||
      event.target.files.length === 0 ||
      !event.target.files[0] ||
      event.target.files.length > 1
    ) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please select one file to upload."
      );
      return false;
    }
    return true;
  }

  public isFileFormatAccepted(
    file: File,
    acceptedMIMETypes: string[]
  ): boolean {
    // get file MIME type
    const fileMIMEType = file.type;
    // if file MIME type is not set or not in the accepted MIME types
    if (
      !fileMIMEType ||
      (acceptedMIMETypes &&
        acceptedMIMETypes.length > 0 &&
        !acceptedMIMETypes.includes(fileMIMEType))
    ) {
      // show error message

      const MIMETypeExtension: { [key: string]: string } = {
        "application/pdf": "pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "docx",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          "pptx",
      };

      const acceptedFileExtensions = acceptedMIMETypes.map(
        (mimeType) => MIMETypeExtension[mimeType] || mimeType
      );

      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `The file type is not supported. Please select a file of the following type: ${acceptedFileExtensions.join(
          ", "
        )}.`
      );
      // stop execution, return
      return false;
    } else {
      return true;
    }
  }

  public isFileSizeTooLarge(file: File, fileSizeLimit = 52_428_800): boolean {
    if (file.size > fileSizeLimit) {
      const maxFileString = Math.round(fileSizeLimit / 1_048_576).toString();
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `The maximum file size is ${maxFileString}MB, please upload a smaller file.`
      );
      LogHelperSingleton.logWithProperties("fileSizeTooBig", {
        Size: fileSizeLimit,
      });
    }

    return file.size >= fileSizeLimit;
  }

  public onDownloadFile(file: File): void {
    const fileUrl = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl);
  }

  private blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type });
  }

  public async onDownloadFileFromUrl(
    url: string,
    fileName: string
  ): Promise<void> {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = this.blobToFile(blob, fileName);
    this.onDownloadFile(file);
  }
}

export const FileHelperSingleton = new FileHelper();
