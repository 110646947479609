// node_modules
import { Editor } from "@tiptap/core";
import { useEditor } from "@tiptap/react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
  EditorContent,
  FindestButton,
  LoadingStatusIndicator,
  Modal,
  Tabs,
} from "Components";
import { ExtractDetailInformation } from "./ExtractDetailInformation/ExtractDetailInformation";
import { AskIgorMenu } from "./AskIgorMenu/AskIgorMenu";
// Styles
import styles from "./askIgorModal.module.scss";
// Enums
import {
  AskIgorMenuItemEnum,
  DocumentObjectTypeEnums,
  ObjectTypeEnum,
  OnboardingTaskNameEnum,
} from "Enums";
// Hooks
import { useAskIgor, useClickOutsideRef, useObserveScrolling } from "Hooks";
// Types
import {
  TAskIgorRequirement,
  TIdNameTypeObjectType,
  TLogEventName,
  TTab,
} from "Types";
// Constants
import { AiConstants } from "Constants";
// Helpers
import {
  AskIgorMenuItemHelperSingleton,
  DocumentTypeHelperSingleton,
  ExtensionsKit,
  GetCustomLink,
  INSERT_CONTENT_COMMAND,
  LogHelperSingleton,
  SET_CONTENT_COMMAND,
} from "Helpers";
import { faLink, faLinkSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Interfaces
import { IAskIgorModalOptions, ISavedDocumentDTO } from "Interfaces";
// Controllers
import { UserOnboardingTasksControllerSingleton } from "Controllers";
// Providers
import { EditorContext } from "Providers";

interface IAskIgorModalProps {
  editor: Editor;
  object: TIdNameTypeObjectType;
  options: IAskIgorModalOptions;
  setOptions: Dispatch<SetStateAction<IAskIgorModalOptions>>;
  savedDocuments: ISavedDocumentDTO[] | undefined;
  selectedSavedDocuments: ISavedDocumentDTO[] | undefined;
}

export const AskIgorModal: FC<IAskIgorModalProps> = ({
  editor,
  object,
  options,
  setOptions,
  savedDocuments,
  selectedSavedDocuments,
}: IAskIgorModalProps) => {
  const [documentTypes, setDocumentTypes] = useState<ObjectTypeEnum[]>([
    ObjectTypeEnum.MagPatent,
    ObjectTypeEnum.ScienceArticle,
    ObjectTypeEnum.UsPatent,
    ObjectTypeEnum.Weblink,
  ]);
  const [documentsSelected, setDocumentsSelected] = useState<string[]>([]);
  const [noSources, setNoSources] = useState<boolean>(false);
  const [showTableBuilder, setShowTableBuilder] = useState<boolean>(false);
  const [linkedDocuments, setLinkedDocuments] =
    useState<ISavedDocumentDTO[] | undefined>(undefined);
  const [
    doCancelExtractDetailInformation,
    setDoCancelExtractDetailInformation,
  ] = useState<boolean>(false);

  // Ref
  const bodyRef = useRef<HTMLDivElement>(null);
  const didTriggerWriteSection = useRef<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const { setIsLinkingModalOpen, setIsObjectToDocumentLinkingModalOpen } =
    useContext(EditorContext);

  const navigate = useNavigate();
  const askIgorModalEditor = useEditor({
    extensions: [...ExtensionsKit, GetCustomLink(navigate, true)],
    editable: false,
  });
  const {
    isGeneratingText,
    aiGeneratedText,
    onSubmitHandlerAsync: askIgorOnSubmitHandlerAsync,
    onCancelCompletion,
    setAIGeneratedText,
    setIsGeneratingText,
  } = useAskIgor({
    askIgorMenuItem: options.selectedMenuItem,
    documentsSelected: options.documentsSelected,
  });
  const observeScrolling = useObserveScrolling({
    ref: bodyRef,
    doStartObserving: isGeneratingText,
  });

  const generalDescriptionTabs = useMemo((): TTab[] => {
    const tabs: TTab[] = [
      { name: "General knowledge" },
      { name: "Linked sources" },
    ];

    return tabs;
  }, []);
  const isAIGeneratedTextEmpty = useMemo(
    () => !aiGeneratedText,
    [aiGeneratedText]
  );
  const textToShowWhileGenerating = useMemo(
    () =>
      "I am generating the answer, it could take a few minutes, please wait...",
    []
  );
  const doShowLoadingIndicator = useMemo(
    () => (isGeneratingText || options.selectedMenuItem === AskIgorMenuItemEnum.GeneralDescription) && aiGeneratedText.length === 0,
    [isGeneratingText, aiGeneratedText, options.selectedMenuItem]
  );
  const doShowText = useMemo(
    () => aiGeneratedText.length > 0,
    [aiGeneratedText.length]
  );
  const didStreamErrorHappen = useMemo(
    () => aiGeneratedText.includes(AiConstants.ERROR_START_MESSAGE),
    [aiGeneratedText]
  );
  const aiGeneratedTextWithoutError = useMemo(
    () => aiGeneratedText.replace(AiConstants.ERROR_START_MESSAGE, ""),
    [aiGeneratedText]
  );

  useEffect(() => {
    if (options.isOpen && !options.isMinimized) {
      LogHelperSingleton.log("AskIgorModalOpened");
    }
  }, [options.isMinimized, options.isOpen]);

  useEffect(() => {
    if (!askIgorModalEditor) return;

    if (doShowText) {
      if (didStreamErrorHappen) {
        SET_CONTENT_COMMAND.action(askIgorModalEditor, {
          content: aiGeneratedTextWithoutError,
        });
      } else {
        SET_CONTENT_COMMAND.action(askIgorModalEditor, {
          content: aiGeneratedText,
        });
      }
    }
  }, [
    aiGeneratedTextWithoutError,
    aiGeneratedText,
    askIgorModalEditor,
    didStreamErrorHappen,
    doShowText,
  ]);

  const onClose = (): void => {
    didTriggerWriteSection.current = false;

    resetAskIgor();

    setOptions({
      defaultInput: undefined,
      selectedMenuItem: undefined,
      isOpen: false,
      isMinimized: false,
      documentsSelected: [],
    });

    observeScrolling.disconnectObserver();
  };

  const onMinimize = (): void => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      isMinimized: true,
    }));
  };

  useClickOutsideRef(modalRef, () => {
    if (modalRef.current && event && !modalRef.current.contains(event.target as Node)) {
      if (typeof (event.target as HTMLElement).className === "string" && (event.target as HTMLElement).className.includes("ReactModal__Overlay")) {
        onMinimize();
      }
    }
  });

  const resetAskIgor = useCallback(() => {
    onCancelCompletion();

    setAIGeneratedText("");

    setIsGeneratingText(false);

    setDoCancelExtractDetailInformation(false);
  }, [onCancelCompletion, setAIGeneratedText, setIsGeneratingText]);

  const onSelectedItemUpdateHandler = useCallback(
    (newSelectedItem: AskIgorMenuItemEnum): void => {
      didTriggerWriteSection.current = true;
      resetAskIgor();
      setOptions({
        defaultInput: !options.selectedMenuItem ? options.defaultInput : "",
        selectedMenuItem: options.selectedMenuItem === AskIgorMenuItemEnum.GeneralDescription ? AskIgorMenuItemEnum.GeneralDescription : newSelectedItem,
        isOpen: true,
        isMinimized: false,
        documentsSelected: options.documentsSelected,
      });
    },
    [
      options.defaultInput,
      options.documentsSelected,
      options.selectedMenuItem,
      resetAskIgor,
      setOptions,
    ]
  );

  const onSubmitHandlerAsync = async (
    item: AskIgorMenuItemEnum,
    text: string,
    dataPointsAmount?: number,
    requirements?: TAskIgorRequirement[]
  ): Promise<void> => {
    if (!object) return;

    onSelectedItemUpdateHandler(item);

    await askIgorOnSubmitHandlerAsync(
      object,
      text,
      documentTypes,
      dataPointsAmount,
      item,
      requirements
    );
  };

  const onAcceptClickHandler = async (
    currentAIGeneratedText: string
  ): Promise<void> => {
    if (!currentAIGeneratedText || !options.selectedMenuItem) return;

    await UserOnboardingTasksControllerSingleton.completeUserOnboardingTaskAsync(
      OnboardingTaskNameEnum.GenerateAskIgorContent
    );

    const insertResultLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedInsertResultLogEventName(
        options.selectedMenuItem
      );

    LogHelperSingleton.log(insertResultLogEventName);

    INSERT_CONTENT_COMMAND.action(editor, {
      content: askIgorModalEditor?.getJSON(),
    });

    onMinimize();
  };

  const onClearHandler = (): void => {
    setAIGeneratedText("");
    setShowTableBuilder(false);
    if (options.selectedMenuItem === AskIgorMenuItemEnum.GeneralDescription) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        selectedMenuItem: AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge,
      }));
    }
  };

  const onCancelHandler = (): void => {
    onCancelCompletion();
    setIsGeneratingText(false);
    setDoCancelExtractDetailInformation(true);
    let newSelectedMenuItem: AskIgorMenuItemEnum | undefined;
    if (options.selectedMenuItem === AskIgorMenuItemEnum.InformationExtraction) {
      newSelectedMenuItem = AskIgorMenuItemEnum.InformationExtraction;
    } else if (
      options.selectedMenuItem === AskIgorMenuItemEnum.GeneralDescription ||
      options.selectedMenuItem === AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge ||
      options.selectedMenuItem === AskIgorMenuItemEnum.GeneralDescriptionUsingLinks
    ) {
      newSelectedMenuItem = AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge;
    } else {
      newSelectedMenuItem = undefined;
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      selectedMenuItem: newSelectedMenuItem,
    }));
  };

  // TODO: find a better way to only trigger this once without using the hacky didTriggerWriteSection ref
  useEffect(() => {
    if (
      options.isOpen &&
      options.defaultInput &&
      options.selectedMenuItem === AskIgorMenuItemEnum.WriteSection &&
      !didTriggerWriteSection.current
    ) {
      didTriggerWriteSection.current = true;

      const documentTypesForWriteSection =
        selectedSavedDocuments && selectedSavedDocuments.length > 0
          ? DocumentTypeHelperSingleton.getObjectDocumentTypesFromSavedDocuments(
            selectedSavedDocuments
          )
          : DocumentObjectTypeEnums;

      askIgorOnSubmitHandlerAsync(
        object,
        options.defaultInput,
        documentTypesForWriteSection,
        undefined,
        options.selectedMenuItem
      );
    }
  }, [
    askIgorOnSubmitHandlerAsync,
    documentTypes,
    isGeneratingText,
    object,
    options.defaultInput,
    options.isOpen,
    options.selectedMenuItem,
    setOptions,
    selectedSavedDocuments,
  ]);

  useEffect(() => {
    if (options.selectedMenuItem === AskIgorMenuItemEnum.GeneralDescription) {
      onSubmitHandlerAsync(
        AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge,
        ""
      );
    }
  }, [options.selectedMenuItem]);


  return (
    <Modal
      isOpen={options.isOpen}
      extraClassNames={{
        overlay: `${styles.overlay} ${options.isMinimized ? styles.hidden : ""
          }`,
        container: styles.container,
        header: styles.header,
        minimizeButton: styles.minimizeButton,
        closeButton: styles.closeButton,
      }}
      onClose={onClose}
      onMinimize={onMinimize}
      shouldCloseOnOverlayClick={false}
    >
      <div
        className={styles.modalContainer}
        ref={modalRef}
      >
        <div className={styles.leftSection}>
          <AskIgorMenu
            object={object}
            selectedSavedDocumentsFormList={selectedSavedDocuments}
            selectedDocumentsOfIgor={options.documentsSelected}
            defaultInput={options.defaultInput}
            selectedItem={options.selectedMenuItem}
            onDocumentTypesChange={setDocumentTypes}
            onSelectedItemUpdate={onSelectedItemUpdateHandler}
            onSubmit={onSubmitHandlerAsync}
            loading={isGeneratingText}
            onEmptyDocumentList={() => {
              setNoSources(true);
            }}
            onSetAskIgorModalOptions={setDocumentsSelected}
            isGeneratingText={isGeneratingText}
            onFetchLinkedDocuments={(
              documents: ISavedDocumentDTO[] | undefined
            ) => {
              setLinkedDocuments(documents);
            }}
          />
        </div>
        <div className={styles.rightSection}>
          {noSources && !doShowLoadingIndicator && !doShowText ? (
            <div className={styles.noSourcesContainer}>
              <FontAwesomeIcon icon={faLinkSlash} />
              <h4>No sources linked to this page</h4>
              <p>
                To use Ask IGOR to its full potential, you have to connect
                documents and/or highlights to this page. IGOR needs these
                sources in order to generate an answer or extract information
                from that is trustworthy.
              </p>
              <p>
                For now, you will only be able to generate a General description
                based on general knowledge, but be mindful to double check its
                result.
              </p>
              <FindestButton
                title="Link documents"
                leftIconName={faLink}
                buttonType="secondary"
                onClick={() => {
                  onMinimize();
                  setIsObjectToDocumentLinkingModalOpen(true);
                  setIsLinkingModalOpen(true);
                }}
              />
            </div>
          ) : null}
          <div
            className={`${styles.body} ${options.selectedMenuItem ===
                AskIgorMenuItemEnum.InformationExtraction &&
                aiGeneratedText.length > 0 &&
                !showTableBuilder
                ? styles.overflowHidden
                : ""
              }`}
            ref={bodyRef}
            onScroll={observeScrolling.onScroll}
          >
            {!noSources &&
              (options.selectedMenuItem ===
                AskIgorMenuItemEnum.GeneralDescription ||
                options.selectedMenuItem ===
                AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge ||
                options.selectedMenuItem ===
                AskIgorMenuItemEnum.GeneralDescriptionUsingLinks) && (
                <Tabs
                  tabs={generalDescriptionTabs}
                  theme="blue"
                  extraClassNames={{
                    disabled:
                      isGeneratingText && !didStreamErrorHappen
                        ? styles.disabled
                        : "",
                  }}
                  defaultSelectedTab={generalDescriptionTabs[0].name}
                  onSelectedTabChange={(tab: string) => {
                    onSubmitHandlerAsync(
                      tab === "General knowledge"
                        ? AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
                        : AskIgorMenuItemEnum.GeneralDescriptionUsingLinks,
                      ""
                    );
                  }}
                />
              )}
            {options.selectedMenuItem ===
              AskIgorMenuItemEnum.InformationExtraction && (
                <ExtractDetailInformation
                  aboutObject={object}
                  documentsSelected={documentsSelected}
                  aiGeneratedText={aiGeneratedText}
                  setAIGeneratedText={setAIGeneratedText}
                  linkedDocuments={linkedDocuments}
                  setIsGeneratingText={setIsGeneratingText}
                  doCancel={doCancelExtractDetailInformation}
                  setDoCancel={setDoCancelExtractDetailInformation}
                  showTableBuilder={showTableBuilder}
                  setShowTableBuilder={setShowTableBuilder}
                />
              )}
            {doShowLoadingIndicator && (
              <div className={styles.loadingIndicatorContainer}>
                <LoadingStatusIndicator size={40} status={1} />
                {textToShowWhileGenerating &&
                  textToShowWhileGenerating.trim().length > 0 && (
                    <p>{textToShowWhileGenerating}</p>
                  )}
              </div>
            )}
            {doShowText && (
              <div
                className={`${styles.generatedContentContainer} ${showTableBuilder ? styles.displayNone : ""
                  } ${options.selectedMenuItem ===
                    AskIgorMenuItemEnum.InformationExtraction
                    ? styles.overflowAuto
                    : ""
                  }`}
              >
                {didStreamErrorHappen ? (
                  <div
                    className={`${styles.errorMessage} ${styles.generatedContent}`}
                  >
                    <h3>Error</h3>
                    <EditorContent
                      className="compactEditorContent"
                      editor={askIgorModalEditor}
                    />
                  </div>
                ) : (
                  <EditorContent
                    editor={askIgorModalEditor}
                    className={`${styles.generatedContent} ${options.selectedMenuItem ===
                        AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge ||
                        options.selectedMenuItem ===
                        AskIgorMenuItemEnum.GeneralDescriptionUsingLinks ||
                        options.selectedMenuItem ===
                        AskIgorMenuItemEnum.GeneralDescription
                        ? styles.hasTabs
                        : options.selectedMenuItem ===
                          AskIgorMenuItemEnum.InformationExtraction
                          ? styles.table
                          : ""
                      }`}
                    savedDocuments={savedDocuments}
                  />
                )}
                <EditorContent
                  editor={askIgorModalEditor}
                  savedDocuments={savedDocuments}
                />
              </div>
            )}
          </div>
          {!didStreamErrorHappen &&
            ((!isGeneratingText && !isAIGeneratedTextEmpty) ||
              isGeneratingText) ? (
            <div className={styles.footer}>
              {!isGeneratingText &&
                !isAIGeneratedTextEmpty &&
                !showTableBuilder && (
                  <>
                    <FindestButton
                      title={
                        options.selectedMenuItem ===
                          AskIgorMenuItemEnum.InformationExtraction
                          ? "Insert table"
                          : "Insert"
                      }
                      onClick={() => {
                        onAcceptClickHandler(aiGeneratedText);
                      }}
                      extraClassName={styles.acceptButton}
                    />
                    {options.selectedMenuItem ===
                      AskIgorMenuItemEnum.InformationExtraction && (
                        <FindestButton
                          title={"Edit table"}
                          buttonType="secondary"
                          onClick={() => {
                            setShowTableBuilder(true);
                          }}
                        />
                      )}
                    <FindestButton
                      title="Clear"
                      buttonType="secondary"
                      onClick={onClearHandler}
                    />
                  </>
                )}
              {isGeneratingText && (
                <FindestButton
                  buttonType="secondary"
                  title="Cancel"
                  onClick={onCancelHandler}
                  extraClassName={styles.cancelButton}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
