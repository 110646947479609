// node_modules
import {
  faBookOpenReader,
  faDiceD6,
  faFile,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
  AddDocument,
  CreateEntityModal,
  CreateQueryWizard,
  CreateStudyModal,
  HasAdvanced,
  Modal,
} from "Components";
import { CreateNewModalCard } from "./CreateNewModalCard";
// Styles
import styles from "./createNewModal.module.scss";
// Providers
import { AuthContext, OnboardingContext } from "Providers";
import { EntityConstants, OnboardingConstants, StudyConstants } from "Constants";

type TCreateNewModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const CreateNewModal: FC<TCreateNewModalProps> = ({
  isOpen,
  closeModal,
}: TCreateNewModalProps) => {
  // Hooks
  const navigate = useNavigate();
  // Contexts
  const { auth: { userEmail }, hasAdvanced } = useContext(AuthContext);
  const { registerSpotlightElementRefs, spotlightDetails, spotlightElement, clearSpotlight } = useContext(OnboardingContext);
  const [objectOptionsContainerDiv, setObjectOptionsContainerDiv] = useState<HTMLDivElement | null>(null);
  const [documentsContainerDiv, setDocumentsContainerDiv] = useState<HTMLDivElement | null>(null);
  const [entityContainerDiv, setEntityContainerDiv] = useState<HTMLDivElement | null>(null);
  const [studyContainerDiv, setStudyContainerDiv] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      !isOpen ||
      !objectOptionsContainerDiv ||
      !documentsContainerDiv ||
      !entityContainerDiv ||
      !studyContainerDiv ||
      [objectOptionsContainerDiv, documentsContainerDiv, entityContainerDiv, studyContainerDiv].includes(spotlightDetails?.element as HTMLDivElement)
    ) return;

    registerSpotlightElementRefs({
      [OnboardingConstants.CREATE_NEW_MODAL_PAGES_CONTAINER]: objectOptionsContainerDiv,
      [OnboardingConstants.CREATE_NEW_MODAL_DOCUMENT_CONTAINER]: documentsContainerDiv,
      [OnboardingConstants.CREATE_NEW_MODAL_ENTITY_CONTAINER]: entityContainerDiv,
      [OnboardingConstants.CREATE_NEW_MODAL_STUDY_CONTAINER]: studyContainerDiv,
    });

    if (spotlightDetails) {
      let extraSpaces = undefined;
      if (spotlightDetails.flowName === OnboardingConstants.CREATE_PAGE_FLOW) {
        extraSpaces = {
          top: 66,
          bottom: 30,
          left: 18,
          right: 18
        };
      }
      spotlightElement(spotlightDetails.flowName, 1, extraSpaces);
    }
  }, [spotlightDetails, spotlightElement, registerSpotlightElementRefs, isOpen, objectOptionsContainerDiv, documentsContainerDiv, entityContainerDiv, studyContainerDiv]);

  const onClose = () => {
    closeModal();

    clearSpotlightIfExists();
  };

  const clearSpotlightIfExists = () => {
    if (spotlightDetails) {
      clearSpotlight();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Create new"
      extraClassNames={styles}
    >
      <div className={`${styles.mainContainer} ${hasAdvanced ? styles.hasAdvanced : ""}`}>
        <div ref={setObjectOptionsContainerDiv} className={styles.objectOptionsContainer}>
          <CreateNewModalCard
            ref={setStudyContainerDiv}
            title="Study"
            iconName={faBookOpenReader}
            extraClassName={styles.newStudyCardHeader}
            description={StudyConstants.CREATE_STUDY_DESCRIPTION}
            clearSpotlightIfExists={clearSpotlightIfExists}
          >
            <CreateStudyModal onCreationDone={onClose} />
          </CreateNewModalCard>
          <CreateNewModalCard
            ref={setEntityContainerDiv}
            title="Entity"
            iconName={faDiceD6}
            extraClassName={styles.newEntityCardHeader}
            description={EntityConstants.CREATE_ENTITY_DESCRIPTION}
            clearSpotlightIfExists={clearSpotlightIfExists}
          >
            <CreateEntityModal onCreationDone={onClose} clearSpotlightIfExists={clearSpotlightIfExists} />
          </CreateNewModalCard>
        </div>
          <CreateNewModalCard
            ref={setDocumentsContainerDiv}
            title="Document"
            iconName={faFile}
            extraClassName={styles.newDocCardHeader}
            description="Manually add a scientific article, patent or webpage directly into your inbox by providing the DOI, patent number or URL."
            clearSpotlightIfExists={clearSpotlightIfExists}
          >
            <AddDocument
              onCreationDone={(savedDocumentId: string) => {
                onClose();
                navigate(`/library/documents/${savedDocumentId}`);
              }}
              clearSpotlight={clearSpotlight}
            />
          </CreateNewModalCard>
        <HasAdvanced>
          <CreateNewModalCard
            title="Query"
            isWider
            iconName={faMagnifyingGlass}
            extraClassName={styles.newQueryCardHeader}
            description="Create a query in order to search through millions of documents."
          >
            <CreateQueryWizard userEmail={userEmail} onCreationDone={onClose} isSimpleMode />
          </CreateNewModalCard>
        </HasAdvanced>
      </div>
    </Modal>
  );
};
