import axios from "axios";
import { ObjectTypeEnum } from "Enums";
import { AxiosHelperSingleton, LogHelperSingleton } from "Helpers";

export class ExportController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/v1/export`;

  /**
   * Sends a request to export a file based on object type and ID.
   *
   * @param objectId - The ID of the object to export.
   * @param type - The type of the object (as defined by ObjectTypeEnum in the backend).
   * @param request - The export page request containing content type and other data.
   * @returns A Blob representing the file to download.
   */
  public async exportFileAsync(
    objectId: string,
    type: ObjectTypeEnum,
    request: { contentType: string }
  ): Promise<void> {
    try {
      const response = await axios.post(
        `${this._resourcePath}/${type}/${objectId}`,
        request,
        {
          responseType: "blob", // Important to handle file responses
        }
      );

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloaded-file"; // Default fallback filename

      // Prefer `filename*` if available, else fallback to `filename`
      const fileNameMatch = contentDisposition?.match(
        /filename\*?=["']?([^;]+)["']?/
      );
      if (fileNameMatch) {
        fileName = decodeURIComponent(fileNameMatch[1])
          .replace(/["']/g, "") // Remove quotes
          .replace(/_/g, ""); // Optional: Remove underscores
      }

      // Create and trigger the file download
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName.trim(); // Trim any leading or trailing spaces
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);

      LogHelperSingleton.logWithProperties("ExportFile", { Type: request.contentType });
    } catch (error) {
      console.error("Export file failed", error);
    }
  }
}

export const ExportControllerSingleton = new ExportController();
