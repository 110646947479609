// node_modules
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// Components
import {
  AddDocument,
  Dropdown,
  DropdownButton,
  FindestButton,
  ListObjectItem,
  SuggestingTextbox,
  Tabs,
} from "Components/Shared";
// Controllers
import {
  ActivityControllerSingleton,
  SavedDocumentControllerSingleton,
  SearchControllerSingleton,
} from "Controllers";
// Enums
import { ObjectTypeEnum, OrderByEnum, SavedDocumentTypeEnum } from "Enums";
// Types
import { fromIInboxListItem, TIdNameTypeObjectType, TInboxListDTO, TOption } from "Types";
// Components
import { Modal } from "../Modal";
// Styles
import sharedModalStyles from "../modal.module.scss";
import styles from "./linkingModal.module.scss";
// Helpers
import {
  LinkingHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
} from "Helpers";
// Contexts
import { DocumentContext } from "Providers";
// Constants
import { LinkingConstants } from "Constants";

type TLinkingModalProps = {
  linkToName?: string;
  linkToType?: ObjectTypeEnum;
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onLinkingDoneAsync?: (
    isLinkingDone: boolean,
    selectedLinkToObject?: TIdNameTypeObjectType | undefined,
    linkType?: string | undefined
  ) => Promise<void>;
  selectedObjects: TIdNameTypeObjectType[];
  excludedSearchTypes?: ObjectTypeEnum[];
  defaultLinkType: TOption<string>;
  forcedLinkType?: TOption<string>;
  onSaveClickCallback?: (selectedLinkToObject: TIdNameTypeObjectType) => void;
};

export const LinkingModal: FC<TLinkingModalProps> = ({
  linkToName,
  linkToType,
  onClose,
  isOpen,
  setIsOpen,
  onLinkingDoneAsync,
  excludedSearchTypes,
  selectedObjects,
  defaultLinkType,
  forcedLinkType,
  onSaveClickCallback,
}: TLinkingModalProps) => {
  // Contexts
  const { refreshDocumentsNotLinkedCount } = useContext(DocumentContext);

  // State
  const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>([]);
  const [isRecentActivityVisible, setIsRecentActivityVisible] =
    useState<boolean>(false);
  const [linkType, setLinkType] = useState<TOption<string>>(
    forcedLinkType ?? defaultLinkType
  );
  const [selectedLinkToObject, setSelectedLinkToObject] =
    useState<TIdNameTypeObjectType | undefined>(undefined);
  const [
    isSuggestingTextboxSuggestionsShown,
    setIsSuggestingTextboxSuggestionsShown,
  ] = useState<boolean>(false);

  const LINK_TO_DOCUMENT_TABS = {
    SEARCH_IN_EXISTING_DOCUMENTS: "Search in existing documents",
    CREATE_A_NEW_DOCUMENT: "Create a new document"
  };

  const [activeTab, setActiveTab] = useState<string>(LINK_TO_DOCUMENT_TABS.SEARCH_IN_EXISTING_DOCUMENTS);

  const documentSearchTypes = [
    ObjectTypeEnum.MagPatent,
    ObjectTypeEnum.ScienceArticle,
    ObjectTypeEnum.UsPatent,
    ObjectTypeEnum.Weblink
  ];

  const objectSearchTypes = [ObjectTypeEnum.Study, ObjectTypeEnum.Entity];
  const allSearchTypes = linkToType === ObjectTypeEnum.Document ? documentSearchTypes : objectSearchTypes;

  // Ref
  const currentNameRef = useRef<string>("");

  // Logic
  // Retrieve recent activity on open
  useEffect(() => {
    if (forcedLinkType) {
      setLinkType(forcedLinkType);
    } else {
      setLinkType(defaultLinkType);
    }
  }, [forcedLinkType, defaultLinkType]);

  useEffect(() => {
    if (isOpen && !linkToName) {
      if (linkToType !== ObjectTypeEnum.Document) {
        retrieveRecentActivityAsync();
      } else {
        getLastInboxItemsAsync();
      }
      setIsRecentActivityVisible(true);
    }
  }, [isOpen, linkToName, linkToType]);

  // Logic
  const searchTypes = useMemo(() => {
    let filteredSearchTypes: ObjectTypeEnum[] = allSearchTypes;
    if (excludedSearchTypes) {
      filteredSearchTypes = allSearchTypes.filter(
        (type) => !excludedSearchTypes.includes(type)
      );
    }
    return filteredSearchTypes;
  }, [excludedSearchTypes, allSearchTypes]);

  const searchTypeStrings = useMemo(() => {
    return searchTypes.map((type) =>
      ObjectTypeHelperSingleton.getObjectTypeDropdownButtonAction(type)
    );
  }, [searchTypes]);

  const getIsCreateButtonEnabled = useCallback((currentName: string) => {
    return currentName.trim().length > 0;
  }, []);

  const retrieveRecentActivityAsync = async () => {
    const recentActivity =
      await ActivityControllerSingleton.getMySimpleActivityAsync();
    if (!recentActivity) {
      setSuggestions([]);
      return;
    }

    setSuggestions(recentActivity.filter((a) => a && a.name && a.name.trim().length > 0));
  };

  const getLastInboxItemsAsync = async () => {
    const newDocuments: TInboxListDTO = await SavedDocumentControllerSingleton.getMyInboxAsync(OrderByEnum.Descending, [], 1);
    if (!newDocuments?.items) {
      setSuggestions([]);
      return;
    }

    const items = newDocuments.items.map((item) => {
      return fromIInboxListItem(item);
    });
    setSuggestions(items);
  };

  const runSuggestionsSearchAsync = useCallback(
    async (
      suggestionValue: string,
      currentSearchTypes: ObjectTypeEnum[]
    ): Promise<void> => {
      // set current name state variable
      setIsRecentActivityVisible(false);

      // set current name ref
      currentNameRef.current = suggestionValue;

      // safety-checks
      if (!suggestionValue) {
        if (linkToType !== ObjectTypeEnum.Document) {
          // show recent activity results when suggestionValue is empty
          retrieveRecentActivityAsync();
          setIsRecentActivityVisible(true);
          return;
        } else {
          // get last inbox items
          getLastInboxItemsAsync();
          setIsRecentActivityVisible(true);
          return;
        }
      }

      // search for suggestions
      const foundSuggestions =
        await SearchControllerSingleton.searchMultipleObjectsAsync(
          suggestionValue,
          currentSearchTypes
        );

      // safety-checks
      if (!foundSuggestions) {
        // reset suggestions state variable
        setSuggestions([]);
        return;
      }

      // set suggestions state variable
      setSuggestions(foundSuggestions);
    },
    [linkToType]
  );

  // if linkToName is set, use it as the current name and trigger suggestions search
  useEffect(() => {
    // if linkToName is set
    if (linkToName) {
      // set current name ref variable
      currentNameRef.current = linkToName;
      // trigger suggestions search
      runSuggestionsSearchAsync(linkToName, searchTypes);
    }
  }, [linkToName, runSuggestionsSearchAsync, searchTypes]);

  const onSuggestionClick = (suggestion: TIdNameTypeObjectType) => {
    // safety-checks
    if (!selectedObjects) return;

    setSelectedLinkToObject(suggestion);
  };

  const onModalRequestClose = () => {
    if (isSuggestingTextboxSuggestionsShown) {
      return;
    }
    resetModalStateAndCloseAsync(false);
  };

  const resetModalStateAndCloseAsync = async (
    isLinkingDone: boolean,
    currentSelectedLinkToObject?: TIdNameTypeObjectType
  ) => {
    // call parent callbacks
    if (onLinkingDoneAsync) {
      onLinkingDoneAsync(
        isLinkingDone,
        currentSelectedLinkToObject,
        linkType.value
      );
    }
    setIsOpen(false);

    // reset component state
    setSuggestions([]);
    currentNameRef.current = "";
    setSelectedLinkToObject(undefined);
    setActiveTab(LINK_TO_DOCUMENT_TABS.SEARCH_IN_EXISTING_DOCUMENTS);

    // refresh documents not linked count
    refreshDocumentsNotLinkedCount();

    // log
    LogHelperSingleton.log("StopLinking");

    // if onClose is set, call it
    if (onClose) {
      onClose();
    }
  };

  const clickCreateOption = async (option: string) => {
    if (!selectedObjects) return;
    option = option.toLowerCase();

    // create object
    const createdObject: TIdNameTypeObjectType | undefined =
      await ObjectTypeHelperSingleton.createObject(
        option,
        currentNameRef.current.trim()
      );

    // safety-checks
    if (!createdObject) {
      // stop execution, return
      return;
    }

    // link selected objects and created object
    const isLinkingDone: boolean = await linkingFromModalAsync(
      createdObject.id,
      createdObject.objectType
    );

    // reset state and close modal
    await resetModalStateAndCloseAsync(isLinkingDone, createdObject);

    if (onSaveClickCallback) {
      onSaveClickCallback(createdObject);
    }
  };

  const linkingFromModalAsync = async (
    objectId: string,
    objectType: ObjectTypeEnum
  ): Promise<boolean> => {
    return await LinkingHelperSingleton.linkAsync(
      objectId,
      objectType,
      selectedObjects,
      linkType.value,
      () => {
        setIsOpen(false);
      }
    );
  };

  const onSaveClick = async () => {
    // safety-checks
    if (selectedLinkToObject) {
      // link selected objects and suggestion
      const isLinkingDone: boolean = await linkingFromModalAsync(
        selectedLinkToObject.id,
        selectedLinkToObject.objectType
      );

      // log
      LogHelperSingleton.log("StructureGraphLinkFromModal");
      LogHelperSingleton.log("CreatedLinkBetweenExistingFromLinkingModal");

      // reset state and close modal
      await resetModalStateAndCloseAsync(isLinkingDone, selectedLinkToObject);

      // if onSaveClickCallback is set, call it
      if (onSaveClickCallback) {
        onSaveClickCallback(selectedLinkToObject);
      }
    }
  };

  const doForceShowSuggestions = useMemo((): boolean => {
    // do force show suggestions if recent activity is visible
    // or linkToName is set (means we opened the linking modal through save as entity menu option)
    if (isRecentActivityVisible || linkToName) {
      return true;
    } else {
      return false;
    }
  }, [isRecentActivityVisible, linkToName]);

  const forcedSuggestionValue = useMemo((): string | undefined => {
    // if linkToName is set, use it as forced suggestion value
    return linkToName ?? undefined;
  }, [linkToName]);

  const onNewDocumentCreated = async (newDocumentId: string, savedDocumentType: SavedDocumentTypeEnum) => {
    const isLinkingDone: boolean = await linkingFromModalAsync(
      newDocumentId,
      ObjectTypeHelperSingleton.documentTypeToObjectType(savedDocumentType)
    );
    resetModalStateAndCloseAsync(isLinkingDone);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => resetModalStateAndCloseAsync(false)}
      onRequestClose={onModalRequestClose}
      header="Add link"
    >
      <div className={sharedModalStyles.section}>
        <div className={sharedModalStyles.title}>Add a link from</div>
        <div className={styles.objectsContainer}>
          <div className={styles.objectsSecondContainer}>
            {selectedObjects?.map((object, index) => (
              <ListObjectItem
                key={object.id}
                object={object}
                iconHasColor={true}
                subItemType={object.type}
                extraClassName={styles.objectItem}
                navigateCallback={() => resetModalStateAndCloseAsync(false)}
              >
                <>
                  {index === 0 && selectedObjects.length - 1 > 0 && (
                    <span className={styles.otherSelectedObjectsCount}>
                      {`+ ${selectedObjects.length - 1}`}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  )}
                </>
              </ListObjectItem>
            ))}
          </div>
        </div>
      </div>
      <div className={sharedModalStyles.section}>
        <div className={sharedModalStyles.title}>Link Type</div>
        <Dropdown
          selectedOption={forcedLinkType ?? linkType}
          handleOptionSelect={(option) => setLinkType(option)}
          options={
            forcedLinkType
              ? [{ group: "1", options: [forcedLinkType] }]
              : [
                {
                  group: "1",
                  options: [
                    LinkingConstants.CHILD_LINK_TYPE,
                    LinkingConstants.PARENT_LINK_TYPE,
                  ],
                },
              ]
          }
          placeholderText="Select link type"
          extraClassNames={{
            groupedList: {
              groupTitle: styles.dropdownGroupTitle,
            },
          }}
        />
      </div>
      {linkToType === ObjectTypeEnum.Document && (
        <Tabs
          tabs={[{ name: LINK_TO_DOCUMENT_TABS.SEARCH_IN_EXISTING_DOCUMENTS }, { name: LINK_TO_DOCUMENT_TABS.CREATE_A_NEW_DOCUMENT }]}
          onSelectedTabChange={setActiveTab}
          extraClassNames={{ container: styles.tabsContainer, tab: styles.tab}}
        />
      )}
      {activeTab === LINK_TO_DOCUMENT_TABS.SEARCH_IN_EXISTING_DOCUMENTS || linkToType !== ObjectTypeEnum.Document ? (
        <div className={sharedModalStyles.section}>
          <div className={sharedModalStyles.title}>Name</div>
          <SuggestingTextbox
            forcedSuggestionValue={forcedSuggestionValue}
            placeholder="Enter name"
            title={
              isRecentActivityVisible
                ? "Recently updated objects"
                : "Universe suggestions"
            }
            onValueChangeHandler={(value) => (currentNameRef.current = value)}
            refreshSuggestionsAsync={(newValue: string) =>
              runSuggestionsSearchAsync(newValue, searchTypes)
            }
            suggestions={suggestions}
            handleSuggestionClick={onSuggestionClick}
            doForceShowSuggestions={doForceShowSuggestions}
            selectedOption={selectedLinkToObject}
            setSelectedOption={setSelectedLinkToObject}
            updateParentIsSuggestionsShown={
              setIsSuggestingTextboxSuggestionsShown
            }
            navigateCallback={() => resetModalStateAndCloseAsync(false)}
          />
        </div>
      ) : (
        <AddDocument onCreationDone={onNewDocumentCreated} onCancel={() => resetModalStateAndCloseAsync(false)}/>
      )}
      {(linkToType !== ObjectTypeEnum.Document || (linkToType === ObjectTypeEnum.Document && activeTab === LINK_TO_DOCUMENT_TABS.SEARCH_IN_EXISTING_DOCUMENTS)) && (
        <div className={sharedModalStyles.footer}>
          {selectedLinkToObject || linkToType === ObjectTypeEnum.Document ? (
            <FindestButton
              isDisabled={selectedLinkToObject === undefined}
              extraClassName={styles.saveButton}
              title="Save"
              onClick={onSaveClick}
            />
          ) : (
            <DropdownButton
              isButtonEnabled={getIsCreateButtonEnabled(currentNameRef.current)}
              optionLabels={searchTypeStrings}
              onClickOption={clickCreateOption}
              extraClassNames={{ dropdownButton: styles.createButton }}
              buttonText="create new"
            />
          )}
          <FindestButton
            buttonType="cancel"
            title="Cancel"
            onClick={() => resetModalStateAndCloseAsync(false)}
            extraClassName={styles.cancelButton}
          />
        </div>
      )}
    </Modal>
  );
};
