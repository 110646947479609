import { ListHeader, StudyItem } from "Components/Shared";
import { IStudyDTO } from "Interfaces";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
// Styles
import { faUnlock } from "@fortawesome/pro-solid-svg-icons";
import { StudyConstants } from "Constants";
import { LockControllerSingleton, StudyControllerSingleton } from "Controllers";
import {
  ObjectTypeEnum,
  OrderByEnum,
  SortTypeEnum,
  ToastTypeEnum,
} from "Enums";
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
import { useCheckboxedList, useObjectReferenceModal } from "Hooks";
import entityLikeListStyles from "Styles/EntityLikeList/entityLikeList.module.scss";
import { TButtonDefinition, TStudiesDTO } from "Types";
import styles from "../DeletedArchive/deletedArchive.module.scss";

export const LockedStudies: FC = () => {
  // State
  const [studies, setStudies] = useState<IStudyDTO[]>([]);
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
  const [totalStudyCount, setTotalStudyCount] = useState<number>(0);
  const [lastPaginationFromDates, setLastPaginationFromDates] = useState<
    Date[]
  >([]);

  // Refs
  const container = useRef<HTMLDivElement | null>(null);

  // Hooks
  const {
    selectedItems: selectedStudies,
    setSelectedItems: setSelectedStudies,
    areAllItemsSelected: areAllStudiesSelected,
    isAnyItemSelected: isAnyStudySelected,
    onSelectAllItems,
    onSelectItem,
  } = useCheckboxedList<IStudyDTO>(studies, "Study", "Studies", (study) => ({
    id: study.id,
    type: "Study",
    name: study.title,
    objectType: ObjectTypeEnum.Study,
  }));
  const {
    referenceModal,
    closeReferenceModal,
    referenceModalProps,
    setReferenceModalProps,
  } = useObjectReferenceModal({
    title: "Unlock",
    icon: faUnlock,
    onClick: () => {
      onUnlockButtonClick(referenceModalProps.id);
    },
  });

  const refreshStudiesAsync = async (
    fromDate: Date | undefined,
    currentSortType: SortTypeEnum
  ): Promise<void> => {
    const orderByType =
      currentSortType === SortTypeEnum.Oldest
        ? OrderByEnum.Ascending
        : OrderByEnum.Descending;
    const newStudies: TStudiesDTO =
      await StudyControllerSingleton.getLockedAsync(orderByType, fromDate);

    // set state variables
    setStudies(newStudies.studies);
    setTotalStudyCount(newStudies.totalStudiesCount);

    // Scroll to top of the component
    container.current?.scrollTo({ top: 0 });
  };

  const onSelectAllCheckboxChange = (isChecked: boolean) => {
    onSelectAllItems(isChecked, studies);
  };

  const onStudyCheckboxChange = (isChecked: boolean, id: string): void => {
    const currentStudy = studies.find((study) => study.id === id);
    if (!currentStudy) {
      return;
    }
    onSelectItem(isChecked, currentStudy, id);
  };

  const onPaginatePreviousAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (lastPaginationFromDates && lastPaginationFromDates.length > 0) {
      const lastPaginationFromDate: Date | undefined =
        lastPaginationFromDates.pop();
      if (lastPaginationFromDates.length > 1) {
        if (lastPaginationFromDate) {
          fromDate = lastPaginationFromDate;
        }
      }
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update studies list
    await refreshStudiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToPreviousLockedStudiesPage");
  };

  const onPaginateNextAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (studies && studies.length > 0) {
      const lastPaginationFromDate: Date =
        studies[studies.length - 1].dateAdded;
      fromDate = lastPaginationFromDate;
      lastPaginationFromDates.push(fromDate);
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update studies list
    await refreshStudiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToNextLockedStudiesPage");
  };

  const updateSortTypeAsync = async (
    newSortType: SortTypeEnum
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    // safety-checks
    if (newSortType === sortType) {
      return;
    }

    // set new sort type
    setSortType(newSortType);

    // update studies list
    await refreshStudiesAsync(undefined, newSortType);

    // reset selected studies
    setSelectedStudies([]);

    // log
    LogHelperSingleton.log("SortLockedStudies");
  };

  const onStudyClick = (study: IStudyDTO): void => {
    setReferenceModalProps({
      isOpen: true,
      id: study.id,
      type: ObjectTypeEnum.Study,
      doIgnoreIsDeleted: true,
    });
  };

  const onUnlockButtonClick = useCallback(
    async (id: string | undefined): Promise<void> => {
      let selectedIds: string[];
      if (id) {
        selectedIds = [id];
      } else {
        // Get selected study ids
        selectedIds = selectedStudies.map((selectedStudy) => selectedStudy.id);
      }

      // Check if any studies are selected
      if (selectedIds.length === 0) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "No studies selected to unlock."
        );
        return;
      }

      // Unlock studies
      for (const selectedId of selectedIds) {
        const isSuccess = await LockControllerSingleton.updateObjectLock(
          selectedId,
          ObjectTypeEnum.Study,
          false
        );

        // If not successful, show error toast
        if (!isSuccess) {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Failed to unlock study."
          );
          return;
        }
      }

      // refresh studies list
      refreshStudiesAsync(undefined, SortTypeEnum.Newest);

      // reset selected studies
      setSelectedStudies([]);

      // Close referenceModal if study is restored from the modal
      if (id) {
        closeReferenceModal();
      }
    },
    [closeReferenceModal, selectedStudies, setSelectedStudies]
  );

  const studiesArchiveButtons = useMemo(() => {
    return [
      {
        title: "Unlock",
        onClick: () => {
          onUnlockButtonClick(undefined);
        },
        icon: faUnlock,
        className: styles.restoreButton,
      },
    ] as TButtonDefinition[];
  }, [onUnlockButtonClick]);

  useEffect(() => {
    refreshStudiesAsync(undefined, SortTypeEnum.Newest);
  }, []);

  return (
    <div className={entityLikeListStyles.entityLikeContainer} ref={container}>
      <div className={styles.listItemsContainer}>
        <ListHeader
          isAllListItemsSelected={areAllStudiesSelected}
          isAnyListItemSelected={isAnyStudySelected}
          listItemCountInterval={StudyConstants.MAXIMUM_STUDIES_TO_RETRIEVE}
          totalListItemCount={totalStudyCount}
          sortType={sortType}
          updateSortType={updateSortTypeAsync}
          onPaginateNext={onPaginateNextAsync}
          onPaginatePrevious={onPaginatePreviousAsync}
          onSelectAllCheckboxChange={onSelectAllCheckboxChange}
          buttonDefinitions={studiesArchiveButtons}
        />
        <div className={entityLikeListStyles.entityLikeItemsList}>
          {studies.map((study: IStudyDTO) => {
            const isSelected =
              selectedStudies.find(
                (selectedStudy) => selectedStudy.id === study.id
              ) !== undefined;
            return (
              <StudyItem
                key={`studyitem-${study.id}`}
                study={study}
                isSelected={isSelected}
                onCheckboxChange={onStudyCheckboxChange}
                onItemClickOverride={onStudyClick}
              />
            );
          })}
        </div>
      </div>
      {referenceModal}
    </div>
  );
};
