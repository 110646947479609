export enum QueryViewOptionsEnum {
    MatchedTerms = "MatchedTerms",
    RelevanceScore = "RelevanceScore",
    PublicationDateScience = "PublicationDateScience",
    PublicationDatePatent = "PublicationDatePatent",
    CitationScore = "CitationScore",
    Affiliation = "Affiliation",
    Author = "Author",
    FilingDate = "FilingDate",
    PatentNumber = "PatentNumber",
    PatentCountry = "PatentCountry",
    Assignee = "Assignee",
    Inventor = "Inventor",
    PublicationDate = "PublicationDate"
}