// node_modules
import axios, { AxiosResponse } from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class SavedWeblinkController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/savedweblink`;

  public async deleteMyAsync(id: string): Promise<boolean> {
    try {
      const response: AxiosResponse = await axios
        .delete(`${this._resourcePath}/my/${id}`);

      if (response) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async uploadLocalDocumentAsync({ title, formFile }: { title: string; formFile: File }): Promise<false | string> {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("formFile", formFile);

      const response: AxiosResponse = await axios.post(`${this._resourcePath}/fromlocalfile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response) {
        return response.data.weblinkId;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
}

export const SavedWeblinkControllerSingleton = new SavedWeblinkController();
