//node_modules
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { FindestButton, SuggestingTextbox } from "Components";
// Styles
import sharedModalStyles from "Components/Shared/Modals/modal.module.scss";
import styles from "./createObjectModal.module.scss";
// Types
import { TIdNameTypeObjectType } from "Types";

type TCreateObjectModalProps<T> = {
    suggestionPlaceholder: string,
    suggestionTitle: string,
    isCreateButtonDisabled?: boolean,
    fromObjectToSuggestionBuilder: (object: T) => TIdNameTypeObjectType,
    searchForSuggestionsAsync: (suggestionValue: string) => Promise<T[]>,
    navigateToSuggestionPath: string,
    onCreateClickAsync: (objectName: string) => Promise<void>,
    children?: React.ReactNode,
    buttonText: string,
    closeModal: () => void
    clearSpotlightIfExists?: () => void;
}

export function CreateObjectModal<T>({
    suggestionPlaceholder,
    suggestionTitle,
    isCreateButtonDisabled,
    fromObjectToSuggestionBuilder,
    searchForSuggestionsAsync,
    navigateToSuggestionPath,
    onCreateClickAsync,
    children,
    buttonText,
    closeModal,
    clearSpotlightIfExists,
}: TCreateObjectModalProps<T>) {
    // State
    const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>([]);

    // Ref
    const objectNameRef = useRef<string>("");

    // Hooks
    const navigate = useNavigate();
    
    // Logic
    const runSuggestionsSearchAsync = useCallback(async (suggestionValue: string): Promise<void> => {
        // set object name
        objectNameRef.current = suggestionValue;
        
        // safety-checks
        if (!suggestionValue) { 
            // reset suggestions state variable
            setSuggestions([]);
            return; 
        }

        // search for suggestions
        const foundObjects: T[] = await searchForSuggestionsAsync(suggestionValue);

        // safety-checks
        if (!foundObjects) { 
            // reset suggestions state variable
            setSuggestions([]);
            return; 
        }

        // set suggestions state variable
        // build suggestions from found objects
        setSuggestions(foundObjects.map((foundObject: T) => fromObjectToSuggestionBuilder(foundObject)));
    }, [fromObjectToSuggestionBuilder, searchForSuggestionsAsync]);

    const getIsCreateButtonDisabled = useCallback((currentObjectName: string) => {
        // if object name is not set, disable create button
        let isDisabled = currentObjectName.trim().length < 1;

        // if is create button disabled is set
        if (isCreateButtonDisabled !== undefined) {
            // check if create button is disabled
            isDisabled = isDisabled || isCreateButtonDisabled;
        }
        
        // return is disabled
        return isDisabled;
    }, [isCreateButtonDisabled]);

    const onValueChangeHandler = (value: string) => {
      objectNameRef.current = value;
      clearSpotlightIfExists?.();
    };

    return (
        <>
            <div className={sharedModalStyles.section}>
                <div className={styles.title}>Title</div>
                <SuggestingTextbox 
                    navigateCallback={closeModal}
                    placeholder={suggestionPlaceholder}
                    title={suggestionTitle}
                    onValueChangeHandler={onValueChangeHandler}
                    refreshSuggestionsAsync={runSuggestionsSearchAsync}
                    suggestions={suggestions} 
                    handleSuggestionClick={(suggestion: TIdNameTypeObjectType) => navigate(`${navigateToSuggestionPath}${suggestion.id}`)} />
            </div>
            {children}
            <FindestButton
                title={buttonText}
                extraClassName={sharedModalStyles.buttonMarginTop}
                onClick={getIsCreateButtonDisabled(objectNameRef.current) ? undefined : () => onCreateClickAsync(objectNameRef.current.trim())}
                isDisabled={getIsCreateButtonDisabled(objectNameRef.current)}
            />
        </>
    );
}