// node_modules
import { FC, useEffect, useMemo, useState } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { ConnectedObject } from "./ConnectedObject/ConnectedObject";
// Styles
import styles from "./connectedObjects.module.scss";
// Interfaces
import { IConnectedObjectsProps } from "Interfaces";
// Helpers
import { ConnectedObjectsHelperSingleton } from "Helpers";

export const ConnectedObjects: FC<IConnectedObjectsProps> = ({ mainObjectId, connectedObjects, onConnectToObjectClick, 
        extraClassName, disableConnectToNewObjectButton, setContainerElementReference, doHideTitleOnEmptyOrUnsetConnectedObjects }) => {

    // State
    const [connectionsShown, setConnectionsShown] = useState<number>(4);

    const onRemoveClick = async (connectedObjectToRemove: TIdNameTypeObjectType) => {
        // remove connected object from query
        await ConnectedObjectsHelperSingleton
            .removeConnectedObjectFromObjectAsync(connectedObjectToRemove, mainObjectId);
    };

    const isTitleHidden = useMemo((): boolean => {
        // if doHideTitleOnEmptyOrUnsetConnectedObjects is true and there are no connected objects
        if (doHideTitleOnEmptyOrUnsetConnectedObjects && (!connectedObjects || connectedObjects.length <= 0)) {
            // return true
            return true;
        }

        // otherwise, return false
        return false;
    }, [connectedObjects, doHideTitleOnEmptyOrUnsetConnectedObjects]);

    const onShowMoreConnectionsClick = () => {
        if (connectionsShown === connectedObjects.length) {
            setConnectionsShown(4);
        } else {
            setConnectionsShown(connectedObjects.length);
        }
    };

    useEffect(() => {
        if (connectionsShown !== 4) {
            setConnectionsShown(connectedObjects.length);
        }
    }, [connectedObjects]);

    // Render
    return (
        <div className={[styles.connectionsSectionContainer, extraClassName ? extraClassName : null].join(" ")}>            
            {(!isTitleHidden) && (<h6 className={styles.connectionsTitle}>Connections</h6>)}
            <div className={styles.connectionsContainer}>
                <div className={styles.connectedObjectsContainer}>
                    {connectedObjects.slice(0, connectionsShown).map((connectedObject: TIdNameTypeObjectType) => {
                        return (
                            <ConnectedObject
                                key={connectedObject.id}
                                connectedObject={connectedObject}
                                onRemoveConnectionClickAsync={() => onRemoveClick(connectedObject)} />
                        );
                    })}
                </div>
                <div className={styles.connectionOptionsContainer}>
                    {connectedObjects.length > 4 ?
                            <button type="button" className={styles.connectToNewObject}
                                onClick={onShowMoreConnectionsClick}>
                                {connectionsShown === connectedObjects.length ? (
                                    <span>Hide connections</span>
                                ) : (
                                    <span>Show all {connectedObjects.length} connections</span>
                                )}
                            </button>
                        :
                            null
                    }
                    {!disableConnectToNewObjectButton ?
                            <div ref={setContainerElementReference} className={styles.connectToNewObject}
                                onClick={onConnectToObjectClick}>
                                <span>+ Connect to Entity or Study</span>
                            </div>
                        :
                            null
                    }
                </div>
            </div>
        </div>
    );
};
